import React from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.separator.OPAQUE};

  > div {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
    font-size: x-large;
    color: ${({ theme }) => theme.color.SECONDARY};
  }
  > div + div {
    font-size: 40px;
    margin: 0 35px;
    color: #b72121;
  }
  > div + div + div {
    color: ${({ theme }) => theme.color.SECONDARY};
    margin: 0;
    margin-top: auto;
    font-size: x-large;
  }
`;

const Header = ({ year, month }: { year: number; month: number }) => {
  const monthName = (month: number) => {
    console.log(month);

    switch (month) {
      case 0:
        return 'JANUARY';
      case 1:
        return 'FEBRUARY';
      case 2:
        return 'MARCH';
      case 3:
        return 'APRIL';
      case 4:
        return 'MAY';
      case 5:
        return 'JUNE';
      case 6:
        return 'JULY';
      case 7:
        return 'AUGUST';
      case 8:
        return 'SEPTEMBER';
      case 9:
        return 'OCTOBER';
      case 10:
        return 'NOVEMBER';
      case 11:
        return 'DECEMBER';

      default:
        return 'UNKNOWN';
    }
  };

  return (
    <Wraper>
      <div>
        <p>{year}</p>
      </div>
      <div>
        <p>{month + 1 < 10 ? `0${month + 1}` : month + 1}</p>
      </div>
      <div>
        <p>{monthName(month)}</p>
      </div>
    </Wraper>
  );
};

export default Header;
