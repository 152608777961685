import {
  CharacterType,
  CharactersType,
} from '@apis/interfaces/characInterface';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageCard from '@components/layouts/ImageCard';
import { CharacterAPI } from '@apis/character';
import { Box } from '@components/frame';
import InfomationForm from './components/InfomationForm';
import { Weapon } from '@apis/interfaces/weapon.interface';

const Wraper = styled(Box)`
  align-items: flex-start;
  > div + div {
    margin-left: 25px;
  }
`;

const InfomationChara = ({
  selectedCharacter,
  setSelectedCharacter,
  selectedWeapon,
}: {
  selectedCharacter: CharactersType | undefined;
  setSelectedCharacter: React.Dispatch<
    React.SetStateAction<CharactersType | undefined>
  >;
  selectedWeapon?: Weapon;
}) => {
  const [characterInfo, setCharacterInfo] = useState<
    undefined | CharacterType
  >();
  const characterApi = new CharacterAPI();

  const getCharacterInfomation = async (id: number) => {
    const data = await characterApi.getCharacterById(id);

    setCharacterInfo(data);
  };

  useEffect(() => {
    if (selectedCharacter) getCharacterInfomation(selectedCharacter?.id);
  }, [selectedCharacter]);

  return (
    <Wraper $center $direction="row">
      <div>
        {selectedCharacter && (
          <ImageCard
            data={{
              id: selectedCharacter.id,
              src: selectedCharacter.img_path,
              star: selectedCharacter.star,
              weapon_type: selectedCharacter.weapon_type,
            }}
            style={{ width: '320px', height: '320px' }}
          />
        )}
      </div>
      <div>
        <InfomationForm
          selectedCharacter={characterInfo}
          selectedWeapon={selectedWeapon}
        />
      </div>
    </Wraper>
  );
};

export default InfomationChara;
