import axios from 'axios';
import { API_PATH } from './constant';

const URL = process.env.API;

type ResponseType = {
  message: string;
  state: number;
  data: any;
};

export class ApiAgent {
  private path = API_PATH.ROOT;
  private getMethod = 'GET';

  constructor({
    path,
    getMethod,
  }: {
    path: string;
    getMethod?: 'GET' | 'POST';
  }) {
    this.path = this.path + path;
    if (getMethod) this.getMethod = getMethod;
  }

  public async getter({
    url,
    body,
  }: {
    url?: { routerName: string; value: any };
    body?: any;
  }): Promise<ResponseType> {
    if (url) this.path += `/${url.routerName}${url.value}`;

    const response = await axios<ResponseType, ResponseType>({
      method: this.getMethod ? this.getMethod : 'GET',
      url: this.path,
      data: body,
    });

    if (response.state >= 400) throw new Error(response.message);

    return response.data;
  }
}
