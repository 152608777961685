import React from 'react';
import styled, { css } from 'styled-components';

const Wraper = styled.div<{
  $fontSize?: 'small' | 'medium' | 'large';
  $directColumn?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div {
    height: auto;

    > p {
      color: ${({ theme }) => theme.color.fontColor.SECONDARY};
      font-size: ${(props) => {
        let size = 'larger';
        if (props?.$fontSize === 'large') size = 'x-large';
        if (props?.$fontSize === 'small') size = 'small';
        if (props?.$fontSize === 'medium') size = 'larger';

        return size;
      }};
    }
  }
  > div + div {
    display: flex;
    margin-top: 10px;
    flex: 1;

    > div {
      margin-right: 15px;
    }

    ${(props) =>
      props?.$directColumn &&
      css`
        flex-direction: column;

        > div {
          margin-left: 0;
          margin-top: 15px;
        }
      `}
  }
`;

const ContentWithTitle = ({
  title,
  fontSize,
  children,
  directColumn,
  style,
}: {
  title?: string;
  fontSize?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  directColumn?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <Wraper $fontSize={fontSize} $directColumn={directColumn}>
      <div>
        <p>{title}</p>
      </div>
      <div style={style}>{children}</div>
    </Wraper>
  );
};

export default ContentWithTitle;
