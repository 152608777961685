import { Card } from '@components/frame';
import React from 'react';
import styled from 'styled-components';
import TodoManagerBtns from './components/TodoManagerBtns';
import TodoManagerPinBox from './components/TodoManagerPinBox';
import TodoManagerText from './components/TodoManagerText';

const Header = styled.div`
  padding: 15px;
  border-bottom: 3px solid ${({ theme }) => theme.separator.OPAQUE};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding: 15px;
    height: max-content;

    &:last-of-type {
      margin-top: auto;
    }
  }
`;

const TodoManager = () => {
  return (
    <Card>
      <Header>주간 매니저</Header>
      <Content>
        <div>
          <TodoManagerBtns />
        </div>
        <div>
          <TodoManagerPinBox />
        </div>
        <div>
          <TodoManagerText />
        </div>
        <div>
          <button>추가</button>
        </div>
      </Content>
    </Card>
  );
};

export default TodoManager;
