import React from 'react';
import styled from 'styled-components';
import Week from '../Home/Calendar/Week';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: ${({ theme }) => theme.radius.APP};

  > div {
    height: max-content;
  }
  > div + div {
    flex: 1;
  }
`;

const Header = styled.div`
  padding: ${({ theme }) => theme.padding.SMALL} 0;
  border-bottom: 3px solid ${({ theme }) => theme.separator.OPAQUE};
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    min-width: 14%;
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.separator.OPAQUE};

    &:first-of-type {
      border-left: none;
    }
  }
`;

const WeekCalendar = () => {
  const data = [1, 2, 3, 4, 5, 6, 7];

  return (
    <Wraper>
      <Header>
        <Week dow days={[]} />
      </Header>
      <Content>
        {data.map((data) => (
          <div key={data}>{data}</div>
        ))}
      </Content>
    </Wraper>
  );
};

export default WeekCalendar;
