import { Button } from '@components/elements';
import CustomModal from '@components/utils/Modal';
import React from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    font-size: x-large;
    display: flex;
  }
  > div + div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    font-size: larger;
  }
`;

const index = ({
  open,
  onConfirm,
  onCancle,
}: {
  open?: boolean;
  onConfirm?: () => void;
  onCancle?: () => void;
}) => {
  return (
    <CustomModal open={open}>
      <Wraper>
        <div>정말로 삭제하시겠습니까?</div>
        <div>
          <Button
            $color="error"
            onClick={() => {
              if (onConfirm) onConfirm();
            }}
          >
            삭제
          </Button>
          <Button
            onClick={() => {
              if (onCancle) onCancle();
            }}
          >
            아니요
          </Button>
        </div>
      </Wraper>
    </CustomModal>
  );
};

export default index;
