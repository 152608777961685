import { CharacterType } from '@apis/interfaces';
import { ItemType } from '@apis/interfaces/itemInterface';
import ImageBox from '@components/ImageBox';
import ImgCard from '@components/ImgCard';
import { Box } from '@components/frame';
import StarButton from '@components/inputs/StarButton';
import FavoritButton from '@components/inputs/FavoritButton';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  converFavoritData,
  convertToImageCard,
  dowTypeToNum,
} from '@services/index';
import { DowType } from '@apis/interfaces/dowInterface';
import { saveFavorit } from '@services/index';
import { todoManager } from '@services/todoManager';
import { enqueueSnackbar } from 'notistack';
import { Weapon } from '@apis/interfaces/weapon.interface';

const Wraper = styled(Box)`
  > div {
    display: flex;
    justify-content: space-between;
  }

  > div + div {
    display: block;
    margin-top: 20px;
    flex: 1;

    > div + div {
      flex: 1;
      margin-top: 20px;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: ${({ theme }) => theme.radius.CONTENT};
  > div {
    font-size: large;
  }
`;

const ItemCard = styled(Box)`
  margin-top: 15px;
  justify-content: space-between;
  background: transparent;

  > div {
    margin-left: 0;
  }
  > div + div {
    margin-left: 10px;
  }
`;

const ImageComponent = ({ items }: { items: ItemType[] }) => {
  return (
    <ItemCard $direction="row">
      {items.map((data) => (
        <ImageBox toolTipTitle={data.name} key={data.id + data.name}>
          <ImgCard src={data.img_path} star={0} />
        </ImageBox>
      ))}
    </ItemCard>
  );
};

const InfomationForm = ({
  selectedCharacter,
  selectedWeapon,
}: {
  selectedCharacter: CharacterType | undefined;
  selectedWeapon?: Weapon;
}) => {
  const [currentCharacter, setCurrentCharacter] = useState<CharacterType>();
  const [ascendItems, setAscendItems] = useState<ItemType[]>([]);
  const [skillItems, setSkillItems] = useState<ItemType[]>([]);
  const [favorit, setFavorit] = useState(false);
  const cardName = ['돌파 재료', '특성 재료'];

  const divideItem = (item: ItemType) => {
    if (item.abyssal || item.monster || item.weekly_boss)
      setSkillItems((arr) => [...arr, item]);
    else if (item.normal_boss || item.monster)
      setAscendItems((arr) => [...arr, item]);
    else setAscendItems((arr) => [...arr, item]);
  };

  const createStateData = (selectedCharacter: CharacterType) => {
    selectedCharacter.items.forEach((data) => {
      divideItem(data);
    });

    setCurrentCharacter(selectedCharacter);
  };

  const findFavorit = () => {
    const todos = todoManager.getTodo();

    const isFavorit = todos.find(
      (data) => data.data.name === selectedCharacter?.name,
    );

    setFavorit(!!isFavorit);
  };

  const clickTodo = () => {
    if (favorit && currentCharacter) {
      todoManager.removeTodo(currentCharacter?.name);
      enqueueSnackbar('즐겨찾기에서 제거되었습니다.', { variant: 'success' });
      setFavorit(false);
    } else {
      const characterData = convertToImageCard({
        character: selectedCharacter,
      });

      if (selectedWeapon) {
        const weaponData = convertToImageCard({ weapon: selectedWeapon });

        todoManager.setTodo({
          data: weaponData,
          type: 'weapon',
          memo: '돌파 육성',
          dow: dowTypeToNum(selectedWeapon?.dow),
        });
      }

      todoManager.setTodo({
        data: characterData,
        type: 'character',
        memo: '특성 육성',
        dow: dowTypeToNum(selectedCharacter?.dow),
      });

      setFavorit(true);
      enqueueSnackbar('즐겨찾기에 추가되었습니다.', { variant: 'success' });
    }
  };

  const clearState = () => {
    setAscendItems([]);
    setSkillItems([]);
  };

  useEffect(() => {
    if (selectedCharacter) {
      if (currentCharacter && currentCharacter.id !== selectedCharacter.id)
        clearState();

      if (!currentCharacter || currentCharacter?.id !== selectedCharacter.id)
        createStateData(selectedCharacter);
    } else clearState();

    findFavorit();
  }, [selectedCharacter]);

  return (
    <Wraper>
      <div>
        <div>
          <p style={{ fontSize: 'xx-large' }}> {selectedCharacter?.name}</p>
          <StarButton
            num={selectedCharacter?.star || 0}
            size="x-large"
            bgdisable
            noneclick
          />
        </div>
        <div>
          <FavoritButton onClick={clickTodo} checked={favorit} />
        </div>
      </div>
      <div>
        {cardName.map((name, idx) => (
          <Card key={name}>
            <div>{name}</div>
            <ImageComponent
              items={name === '돌파 재료' ? ascendItems : skillItems}
            />
          </Card>
        ))}
      </div>
    </Wraper>
  );
};

export default InfomationForm;
