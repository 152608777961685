import { Box } from '@components/frame';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import starImg from '../../asset/icons/levelStar.png';

const Wraper = styled(Box)<{
  $bgdisable?: boolean;
  $noneclick?: boolean;
  $checked?: boolean;
}>`
  padding: 3px 8px;
  width: fit-content;
  border-radius: 1rem;
  height: auto;
  background-color: ${({ theme }) => theme.bg.TERTIARY};

  cursor: default;

  ${(props) =>
    props?.$bgdisable &&
    css`
      padding: 0;
      background: transparent;
    `}

  ${(props) =>
    !props?.$noneclick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.bg.SECONDARY};
      }
    `}
  ${(props) =>
    props?.$checked &&
    css`
      background-color: red;
    `}
`;

const StarButton = ({
  num,
  bgdisable,
  noneclick,
  size,
  checked,
  onClick,
}: {
  num: number;
  bgdisable?: boolean;
  noneclick?: boolean;
  size?: 'small' | 'large' | 'x-large';
  checked?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const [starSize, setStarSize] = useState<number>(20);
  const arr = new Array(num);
  arr.fill(1);

  useEffect(() => {
    if (size) {
      if (size === 'small') setStarSize(15);
      else if (size === 'large') setStarSize(25);
      else setStarSize(30);
    }
  }, []);

  return (
    <Wraper
      $direction="row"
      $bgdisable={bgdisable}
      $noneclick={noneclick}
      $checked={checked}
      onClick={onClick}
    >
      {arr.map((data, idx) => {
        return (
          <img
            key={idx}
            src={starImg}
            alt=""
            style={{ width: starSize + 'px', height: starSize + 'px' }}
          />
        );
      })}
    </Wraper>
  );
};

export default StarButton;
