import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box as _Box } from '@components/frame';
import { Button as _Button, Link } from '@components/elements';
import styled from 'styled-components';
import { PATH, IMAGE_TAG } from '@constants';
import Tooltip from '@components/Tooltip';
import FooterButton from '@components/inputs/FooterButton';
import ImageBox from '@components/ImageBox';

const Container = styled(_Box)`
  background-color: transparent;
  width: 100%;
  height: 100%;
`;

const BtnContainer = styled(_Box)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  background: rgba(12, 12, 12, 0.7);

  > a {
    /* margin-bottom: 20px; */
  }

  :first-child {
    margin-left: 0;
    border-left: 0;
  }
`;

type PathKeyType = keyof typeof PATH;

const Footer = () => {
  const [path, usePath] = useState('');
  const [buttons, setButtons] = useState<string[]>([]);
  const location = useLocation();

  const checkCurrentRouter = (pathName: string) => {
    if (pathName === location.pathname) return true;

    return false;
  };

  useEffect(() => {
    const keys = Object.keys(PATH);

    setButtons(keys);
  }, []);

  return (
    <Container>
      <BtnContainer $direction="row">
        {buttons.map((data, idx) => (
          <Link to={PATH[data].URL} key={PATH[data].NAME + idx}>
            <FooterButton
              icon={IMAGE_TAG[data]}
              activate={checkCurrentRouter(PATH[data].URL)}
              title={PATH[data].NAME}
            />
            {/* <ImageBox toolTipTitle={PATH[data].NAME}>
            </ImageBox> */}
          </Link>
        ))}
      </BtnContainer>
    </Container>
  );
};

export default Footer;
