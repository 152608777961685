import {
  CharacterType,
  CharactersType,
} from '@apis/interfaces/characInterface';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageCard from '@components/layouts/ImageCard';
import { CharacterAPI } from '@apis/character';
import { Box } from '@components/frame';
import { Weapon } from '@apis/interfaces/weapon.interface';
import Describe from './Describe';
//   import InfomationForm from './components/InfomationForm';

const Wraper = styled(Box)`
  > div + div {
    margin-left: 25px;
    width: 100%;
  }
`;

const InfomationTab = ({ infomation }: { infomation: Weapon | undefined }) => {
  //   useEffect(() => {
  //     if (infomation) getCharacterInfomation(selectedCharacter?.id);
  //   }, [infomation]);

  return (
    <Wraper $center $direction="row">
      <div>
        {infomation && (
          <ImageCard
            data={{
              id: infomation.id,
              src: infomation.img_path,
              star: infomation.star,
              weapon_type: infomation.weapon_type,
              name: '',
            }}
            style={{ width: '280px', height: '280px' }}
          />
        )}
      </div>
      <div>
        <Describe infomation={infomation} />
      </div>
    </Wraper>
  );
};

export default InfomationTab;
