import styled, { css } from 'styled-components';

export const Frame = styled.div`
  width: 65px;
  height: 65px;
  border-radius: ${({ theme }) => theme.radius.APP};
  /* overflow: hidden; */
`;

export const Box = styled.div<{
  $secondary?: boolean;
  $center?: boolean;
  $direction?: 'row';
  $padding?: number;
  $shadow?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.$padding &&
    css`
      padding: ${props.$padding}px;
    `}
  background-color: ${({ theme }) => theme.bg.PRIMARY};
  color: ${({ theme }) => theme.color.PRIMARY};
  ${(props) => {
    // props.$center &&
    // css`
    //   align-items: center;
    //   justify-content: center;
    // `

    if (props.$center)
      return css`
        align-items: center;
        justify-content: center;
      `;
  }}
  ${(props) =>
    props.$direction &&
    css`
      flex-direction: row;
    `};
  ${(props) =>
    props.$shadow &&
    css`
      box-shadow: ${({ theme }) => theme.shadow.BLUR};
    `};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: ${({ theme }) => theme.radius.APP};

  > div {
    /* height: max-content; */
  }
  > div + div {
    flex: 1;
  }
`;
