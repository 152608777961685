import { Weapon } from '@apis/interfaces/weapon.interface';
import ImageBox from '@components/ImageBox';
import RoundButton from '@components/displays/RoundButton';
import { Button } from '@components/elements';
import { Box } from '@components/frame';
import StarButton from '@components/inputs/StarButton';
import ImageCard from '@components/layouts/ImageCard';
import React from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  > div {
    width: 30%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  > div + div {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    > div {
      margin-bottom: 5px;
    }
    > div + div {
      margin-bottom: 15px;
    }
  }

  > div + div + div {
    justify-content: flex-end;
    width: fit-content;
    margin-left: 15px;
    flex: none;
    height: auto;
  }
`;

const DescribeWeapon = ({
  weapon,
  onChange,
}: {
  weapon: Weapon;
  onChange?: () => void;
}) => {
  return (
    <Wraper>
      <div>
        <ImageCard
          data={{
            id: weapon.id,
            src: weapon.img_path,
            star: weapon.star,
            weapon_type: weapon.weapon_type,
          }}
          style={{
            width: 'revert-layer',
            height: 'auto',
          }}
        />
      </div>
      <div>
        <div>
          <h2>{weapon.name}</h2>
        </div>
        <div>
          <StarButton
            num={weapon?.star || 0}
            size="large"
            bgdisable
            noneclick
          />
        </div>
        <Box
          $direction="row"
          style={{ alignItems: 'center', background: 'transparent' }}
        >
          <RoundButton
            src={weapon?.infomations?.attribute?.img_path || ''}
            style={{ marginRight: '15px' }}
          />
          <p style={{ fontSize: 'larger' }}>
            {weapon?.infomations?.attribute?.name}
          </p>
        </Box>
        <div>
          <span style={{ color: '#a2a2a2' }}>
            {weapon?.infomations?.describe}
          </span>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            if (onChange) onChange();
          }}
        >
          변경
        </Button>
      </div>
    </Wraper>
  );
};

export default DescribeWeapon;
