import { Box } from '@components/frame';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import WeaponTypeButtons from '@components/inputs/WeaponTypeButtons';
import IconInput from '@components/inputs/IconInput';
import { CharactersType } from '@apis/interfaces/characInterface';
import FilterBox from '@components/layouts/FilterBox';
import ImageTable, { FilterType } from '@components/layouts/ImageTable';
import { Weapon, Weapons } from '@apis/interfaces/weapon.interface';
import { WeaponAPI } from '@apis/weapon';
import { ImageCardType } from '@components/layouts/ImageCard';

const Wraper = styled(Box)<{ selected?: boolean }>`
  width: 90vw;
  transition: 0.3s;

  ${(props) =>
    props?.selected &&
    css`
      width: calc(100vw - 649px);
    `}

  > div {
    height: max-content;
    padding: 1rem 1.3rem;
  }

  > div + div {
    height: fit-content;
  }
`;

const WeaponList = ({
  selectedId,
  setSelectedId,
}: {
  selectedId: number | undefined;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const [filter, setFilter] = useState<FilterType>({
    name: '',
    star: [],
    elemental: [],
    weaponType: [],
  });
  const [filteredCharacters, setFilteredCharacters] = useState<
    CharactersType[]
  >([]);

  const [weapons, setWeapons] = useState<Weapons[]>([]);
  const [display, setDisplay] = useState<ImageCardType[]>([]);
  const weaponApi = new WeaponAPI();

  const getAllWeapon = async () => {
    const data = await weaponApi.getAllWeapons();

    convertDisplay(data);
    setWeapons(data);
  };

  const convertDisplay = (data: Weapons[]) => {
    const result: ImageCardType[] = data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        src: data.img_path,
        star: data.star,
        weapon_type: data.weapon_type,
        weaponAttribute: data?.weaponAttribute,
      };
    });

    setDisplay(result);
  };

  const onSelectedCardId = (id: number) => {
    // setSelectedWeapon()
  };

  useEffect(() => {
    getAllWeapon();
  }, []);

  return (
    <Wraper selected={selectedId !== undefined}>
      <FilterBox
        filter={filter}
        setFilter={setFilter}
        starButtons={[5, 4, 3, 2, 1]}
        showWeaponType
        showWeaponAttri
      />
      <ImageTable
        list={display}
        filter={filter}
        setSelectedId={(num) => {
          if (setSelectedId) setSelectedId(num);
        }}
      />
    </Wraper>
  );
};

export default WeaponList;
