import React from 'react';
import styled from 'styled-components';
import { Box as _Box } from '@components/frame';
import Footer from './Footer';

const Layer = styled(_Box)`
  > div {
    overflow: hidden;
    flex: 1;
  }

  > div + div {
    height: 6%;
    width: 100%;
    flex: none;
  }
`;

const Layout = ({ contents }: { contents: React.ReactElement }) => {
  return (
    <Layer>
      {contents}
      <div>
        <Footer />
      </div>
    </Layer>
  );
};

export default Layout;
