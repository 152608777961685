import { Box } from '@components/frame';
import React from 'react';
import styled, { css } from 'styled-components';
import starImg from '../../asset/icons/levelStar.png';
import ElementalIcon from '@components/ElementalIcon';

const Wraper = styled(Box)<{ $selected?: boolean }>`
  width: 128px;
  height: 178px;
  border-radius: ${({ theme }) => theme.radius.APP};
  position: relative;
  background-color: ${({ theme }) => theme.bg.SECONDARY};
  transition: 0.25s;
  cursor: pointer;

  overflow: hidden;

  &:hover {
    transform: scale(1.2);
  }

  ${(props) =>
    props.$selected &&
    css`
      transform: scale(1.2);
      /* border: 0.2rem solid ${({ theme }) => theme.bg.SECONDARY}; */
    `}
`;

const ElementalWraper = styled(Box)`
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
`;

const FavoritWraper = styled(Box)`
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
`;

const ImgWraper = styled.div<{ $starLevel: number }>`
  height: 100%;
  width: 100%;
  transition: 0.4s;
  position: relative;

  ${(props) =>
    css`
      background-color: ${({ theme }) => {
        switch (props.$starLevel) {
          case 5:
            return theme.starColor.FIVE;
          case 4:
            return theme.starColor.FOUR;
          case 3:
            return theme.starColor.THREE;
          case 2:
            return theme.starColor.TWO;
          default:
            return theme.starColor.ONE;
        }
      }};
    `}
`;

const StarCards = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: max-content;
  display: flex;
`;

const NameBox = styled(Box)`
  width: 100%;
  height: 50px;
  margin-top: auto;
  text-align: center;
  background-color: ${({ theme }) => theme.bg.PRIMARY};
  transition: 0.25s;
`;

const Star = ({ stars }: { stars: number }) => {
  const arr = new Array(stars).fill(1);

  return (
    <>
      {arr.map((data, idx) => (
        <img
          key={idx + starImg}
          src={starImg}
          alt=""
          style={{
            width: '18px',
            height: '18px',
          }}
        />
      ))}
    </>
  );
};

export type ImageCardType = {
  id: number;
  name: string;
  src: string;
  star: number;
  weapon_type: number;
  weaponAttribute?: number;
  elemental?: number;
};

const ImageCard = ({
  data,
  seleted,
  displayStars,
  displayElemental,
  style,
  hiddenName,
  onClick,
}: {
  data: ImageCardType;
  seleted?: boolean;
  displayStars?: boolean;
  displayElemental?: boolean;
  style?: React.CSSProperties | undefined;
  hiddenName?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { id, name, src, star } = data;

  return (
    <Wraper
      $center={true}
      $shadow
      onClick={onClick}
      $selected={seleted}
      style={style}
    >
      <ImgWraper $starLevel={star}>
        <img
          crossOrigin="anonymous"
          src={src}
          alt=""
          style={{
            width: '100%',
            height: '100%',
          }}
        />
        {displayStars && (
          <StarCards>
            <Star stars={star} />
          </StarCards>
        )}
      </ImgWraper>

      {displayElemental && (
        <ElementalWraper>
          <ElementalIcon id={data?.elemental || 1} />
        </ElementalWraper>
      )}
      {/* {displayFavorit && (
        <FavoritWraper>
          <ElementalIcon id={data?.elemental || 1} />
        </FavoritWraper>
      )} */}
      {name && !hiddenName && <NameBox $center>{name}</NameBox>}
    </Wraper>
  );
};

export default ImageCard;
