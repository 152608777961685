import { defaultValues } from '@apis/defaultValues';
import { ItemType } from '@apis/interfaces/itemInterface';
import { Weapon } from '@apis/interfaces/weapon.interface';
import { WeaponType_Type } from '@apis/interfaces/weaponTypeInterface';
import RoundButton from '@components/displays/RoundButton';
import { Box } from '@components/frame';
import FavoritButton from '@components/inputs/FavoritButton';
import StarButton from '@components/inputs/StarButton';
import ItemBox from '@components/layouts/ItemBox';
import { convertToImageCard, dowTypeToNum } from '@services/index';
import { todoManager } from '@services/todoManager';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wraper = styled(Box)`
  > div {
    display: flex;
    height: max-content;
    justify-content: space-between;
  }

  > div + div {
    display: block;
    margin-top: 20px;
    flex: 1;
  }
`;

const Describe = ({ infomation }: { infomation?: Weapon }) => {
  const [attribute, setAttribute] = useState<WeaponType_Type>({
    id: 0,
    name: '',
    kor_name: '',
    img_path: '',
  });

  const getAttribute = (id: number | undefined) => {
    if (!id) return '';

    const weaponAttributes = defaultValues.get().weaponAttributes;

    const path = weaponAttributes.find((data) => data.id === id);

    if (!path) return '';

    setAttribute(path);
  };

  const clickFavorit = () => {
    const data = convertToImageCard({ weapon: infomation });
    todoManager.setTodo({
      data,
      type: 'weapon',
      memo: '돌파 육성',
      dow: dowTypeToNum(infomation?.dow),
    });

    enqueueSnackbar({
      message: '즐겨찾기에 추가되었습니다.',
      variant: 'success',
    });
  };

  useEffect(() => {
    getAttribute(infomation?.weaponAttribute);
  }, [infomation]);

  return (
    <Wraper>
      <div>
        <div>
          <p style={{ fontSize: 'xx-large' }}> {infomation?.name}</p>
          <StarButton
            num={infomation?.star || 0}
            size="x-large"
            bgdisable
            noneclick
          />
        </div>
        <div>
          <FavoritButton onClick={clickFavorit} />
        </div>
      </div>
      <div>
        <Box $direction="row" style={{ alignItems: 'center' }}>
          {attribute.name !== 'unknown' && (
            <RoundButton
              src={attribute.img_path}
              style={{ marginRight: '15px' }}
            />
          )}
          <p>{attribute.kor_name}</p>
        </Box>
      </div>
      <div>
        <ItemBox title="돌파 재료" items={infomation?.items || []} />
      </div>
    </Wraper>
  );
};

export default Describe;
