import { ItemAPI } from '@apis/item';
import FilterBox from '@components/layouts/FilterBox';
import { ImageCardType } from '@components/layouts/ImageCard';
import ImageTable, { FilterType } from '@components/layouts/ImageTable';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div {
    width: 100%;
  }
  > div + div {
    flex: 1;
    overflow-y: auto;
  }
`;

const WeekCalendarItemList = ({
  list,
  setSelected,
}: {
  list?: ImageCardType[];
  setSelected?: (selected: ImageCardType) => void;
}) => {
  const [filter, setFilter] = useState<FilterType>({
    name: '',
    elemental: [],
    star: [],
    weaponType: [],
    weaponAttribute: [],
  });

  const findSelected = (id: number) => {
    const finded = list?.find((data) => data.id === id);

    if (setSelected && finded) setSelected(finded);
  };

  return (
    <Wraper>
      <div>
        <FilterBox filter={filter} setFilter={setFilter} />
      </div>
      <div>
        <ImageTable list={list} setSelectedId={findSelected} filter={filter} />
      </div>
    </Wraper>
  );
};

export default WeekCalendarItemList;
