import React from 'react';
import styled from 'styled-components';

import Collect from '../../../asset/icons/Collect_Pin.png';
import Fishing from '../../../asset/icons/Fishing_Pin.png';
import Hunting from '../../../asset/icons/Hunting_Pin.png';
import Mineral from '../../../asset/icons/Mineral_Pin.png';
import Monster from '../../../asset/icons/Monster_Pin.png';
import Quest from '../../../asset/icons/Quest_Pin.png';
import Star from '../../../asset/icons/Star_Pin.png';
import ImgCard from '@components/ImgCard';

const arr = [Collect, Fishing, Hunting, Mineral, Monster, Quest, Star];

const Wraper = styled.div`
  display: flex;
  flex-direction: column;

  > div + div {
  }
`;

const TodoManagerPinBox = () => {
  return (
    <Wraper>
      <div>핀</div>
      <div>
        {arr.map((data) => (
          <img src={data} />
        ))}
      </div>
    </Wraper>
  );
};

export default TodoManagerPinBox;
