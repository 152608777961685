import React from 'react';
import styled from 'styled-components';
import Day from './Day';
import { DayType } from '.';

const Wraper = styled.div`
  display: flex;
  height: 15.6%;
  justify-content: space-between;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Week = ({ days, dowList }: { days: DayType[]; dowList?: string[] }) => {
  if (dowList?.length)
    return (
      <Wraper>
        {dowList.map((data) => (
          <Day key={data} bgDisable center direction="row" disableContent>
            {data}
          </Day>
        ))}
      </Wraper>
    );

  return (
    <Wraper>
      {days.map((data, idx) => (
        <Day day={data} key={data.date + idx} />
      ))}
    </Wraper>
  );
};

export default Week;
