export const API_PATH = {
  ROOT: process.env.API,
  DEFAULT: '/defaultValues',
  CHARACTER: '/characters',
  WEAPON: '/weapons',
  ELEMENTAL: '/elemental',
  REGION: '/region',
  WEAPON_TYPE: '/weaponType',
  ITEM: '/items',
};

export const API_STATE = {};
