import React, { useEffect, useState } from 'react';
import RoundButton from './displays/RoundButton';
import { defaultValues } from '@apis/defaultValues';

type PathType = {
  id: number;
  name?: string;
  path: string;
};

const DefaultValuesComponent = ({
  elemental,
  weaponType,
  weaponAttri,
  direction,
  checkedIds,
  onClick,
}: {
  elemental?: boolean;
  weaponType?: boolean;
  weaponAttri?: boolean;
  direction?: 'top' | 'bottom';
  checkedIds?: number[];
  onClick?: (id: number) => void;
}) => {
  const [pathList, setPathList] = useState<PathType[]>([]);

  const createPathList = () => {
    const elementalList = defaultValues.get();
    const result: PathType[] = [];

    if (elemental)
      elementalList.elementals.forEach((data) => {
        if (data.name !== 'unknown')
          result.push({ id: data.id, path: data.img_path });
      });

    if (weaponType)
      elementalList.weaponTypes.forEach((data) => {
        if (data.name !== 'unknown')
          result.push({
            id: data.id,
            path: data.img_path,
            name: data.kor_name,
          });
      });

    if (weaponAttri)
      elementalList.weaponAttributes.forEach((data) => {
        if (data.name !== 'unknown')
          result.push({
            id: data.id,
            path: data.img_path,
            name: data.kor_name,
          });
      });

    setPathList(result);
  };

  useEffect(() => {
    if (elemental || weaponType || weaponAttri) createPathList();
  }, []);

  return (
    <>
      {pathList.map((data) => (
        <RoundButton
          src={data.path}
          key={data.path}
          title={data?.name}
          direction={direction}
          checked={
            !!checkedIds?.find((checked) => checked === data.id) || false
          }
          onClick={() => {
            if (onClick) onClick(data.id);
          }}
        />
      ))}
    </>
  );
};

export default DefaultValuesComponent;
