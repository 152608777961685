import { Box } from '@components/frame';
import React from 'react';
import styled from 'styled-components';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { DESIGN_PATTERN } from '../../styles/theme';

const AddBox = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 3px solid ${({ theme }) => theme.separator.OPAQUE};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.bg.TERTIARY};
  }
`;

const AddableButton = ({
  iconSize,
  onClick,
}: {
  iconSize?: number;
  onClick?: () => void;
}) => {
  return (
    <AddBox
      $center
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <IoMdAddCircleOutline
        size={iconSize ? iconSize + 'em' : '4em'}
        style={{ color: DESIGN_PATTERN.separator.OPAQUE }}
      />
    </AddBox>
  );
};

export default AddableButton;
