import React, { useState } from 'react';
import { Box, Card } from '@components/frame';
import ContentWithTitle from '@components/layouts/ContentWithTitle';
import styled from 'styled-components';

const Wraper = styled(Card)`
  width: 40%;
  height: 20%;
  padding: ${({ theme }) => theme.padding.MEDIUM};
`;

const Credit = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Wraper>
      <ContentWithTitle title="데이터 참고" fontSize="large" directColumn>
        <div style={{ fontSize: 'large' }}>
          <p>
            데이터 및 이미지:{' '}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wiki.hoyolab.com/pc/genshin/home"
                style={{ textDecoration: 'none', color: '#657EF8' }}
              >
                HoYoWiki!
              </a>
            </span>
          </p>
        </div>
        <div style={{ fontSize: 'large' }}>
          <p>
            아이콘:{' '}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://genshin-impact.fandom.com/wiki/Genshin_Impact_Wiki"
                style={{ textDecoration: 'none', color: '#657EF8' }}
              >
                Genshin Impact Wiki - Fandom
              </a>
            </span>
          </p>
        </div>
      </ContentWithTitle>
    </Wraper>
  );
};

export default Credit;
