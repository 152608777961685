import React, { useEffect, useState } from 'react';
import Calendar from './Calendar';
import Today from './Today';
import styled from 'styled-components';
import WeekCalendar from './WeekCalendar';
import { TodoType } from '@types';
import { todoManager } from '@services/todoManager';
import moment from 'moment';
import { defaultValues } from '@apis/defaultValues';
import ConfirmModal from './ConfirmModal';
import Logo from './Logo';

const Wraper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;

  > div {
    width: 58%;
    height: 100%;
  }

  > div + div {
    flex: 1;
  }
`;

const Left = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    width: 100%;
    height: 55%;
    padding: ${({ theme }) => theme.padding.LARGE};
    padding-bottom: 0;
  }

  > div + div {
    height: 10px;
    padding-bottom: ${({ theme }) => theme.padding.LARGE};
    flex: 1;
  }
`;
const Right = styled.div`
  > div {
    padding: ${({ theme }) => theme.padding.LARGE};
    padding-left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > div {
      min-height: 50%;
      height: 55%;
    }
    > div + div {
      width: 60%;
      margin-top: 30px;
      flex: 1;
      margin-left: auto;
    }
  }
`;

const index = () => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteTodo, setDeleteTodo] = useState<TodoType | undefined>(undefined);
  const [todos, setTodos] = useState<TodoType[]>([]);
  const [todayId, setTodayId] = useState(0);
  const [update, setUpdate] = useState(false);

  const addCustomTodo = (todo: TodoType) => {
    todoManager.setTodo(todo);
    setTodos([]);
    setUpdate(true);
  };

  const getTodoData = () => {
    const data = todoManager.getTodo();

    if (data) setTodos(data);
  };

  const getTodayId = () => {
    const todayName = moment().format('dddd');
    const dows = defaultValues.get().dows;

    const today = dows.find((data) => data.name === todayName);

    if (today) setTodayId(today?.id);
  };

  const onDeleteTodo = (todo: TodoType) => {
    setDeleteTodo(todo);
    setOpenModal(true);
  };

  useEffect(() => {
    getTodayId();
    getTodoData();

    if (update) setUpdate(false);
  }, [update, todos]);

  return (
    <Wraper>
      <Left>
        <div>
          <div style={{ width: '50%' }}>
            <Logo />
          </div>
          <div style={{ flex: 1, marginLeft: 'auto' }}>
            <Calendar />
          </div>
        </div>
        <div>
          <WeekCalendar
            todoList={todos}
            todayId={todayId}
            onDelete={onDeleteTodo}
            addTodo={addCustomTodo}
          />
        </div>
      </Left>
      <Right>
        <div>
          <div>
            <Today todoList={todos} todayId={todayId} />
          </div>
        </div>
      </Right>

      <ConfirmModal
        open={openModal}
        onCancle={() => {
          setOpenModal(false);
          setDeleteTodo(undefined);
        }}
        onConfirm={() => {
          if (deleteTodo) {
            const newList = todoManager.removeTodo(deleteTodo.data.name);
            setDeleteTodo(undefined);
            setTodos(newList);
          }
          setOpenModal(false);
        }}
      />
    </Wraper>
  );
};

export default index;
