import { CharacterType } from '@apis/interfaces';
import { DowType } from '@apis/interfaces/dowInterface';
import { Weapon, Weapons } from '@apis/interfaces/weapon.interface';
import { ImageCardType } from '@components/layouts/ImageCard';
import { TodoType } from '@types';

export const convertToImageCard = (data: {
  weapon?: Weapons;
  character?: CharacterType;
}): ImageCardType => {
  const { weapon, character } = data;
  let result: ImageCardType = {
    id: 0,
    name: '',
    src: '',
    star: 0,
    weapon_type: 0,
    weaponAttribute: 0,
  };

  if (weapon)
    return {
      id: weapon.id,
      name: weapon.name,
      src: weapon.img_path,
      star: weapon.star,
      weapon_type: weapon.weapon_type,
      weaponAttribute: weapon?.weaponAttribute,
    };

  if (character)
    return {
      id: character.id,
      name: character.name,
      src: character.avatar_img_path,
      star: character.star,
      weapon_type: character.weapon_type,
    };

  return result;
};

export const converFavoritData = (data: TodoType) => {};

export const saveFavorit = (data: TodoType) => {
  localStorage.setItem('Todo', JSON.stringify(data));
};

export const dowTypeToNum = (dows: DowType[] | undefined) => {
  if (!dows) return [];

  return dows.map((data) => data.id);
};
