import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TodayHeader from './TodayHeader';
import TodayContent from './TodayContent';
import { TodoType } from '@types';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: ${({ theme }) => theme.radius.PAGE};
  padding: ${({ theme }) => theme.padding.MEDIUM};

  > div {
    height: max-content;
  }
  > div + div {
    flex: 1;
  }
`;

const index = ({
  todayId,
  todoList,
}: {
  todayId: number;
  todoList: TodoType[];
}) => {
  useEffect(() => {}, [todoList]);

  return (
    <Wraper>
      <div>
        <TodayHeader />
      </div>
      <div>
        <TodayContent todoList={todoList} todayId={todayId} />
      </div>
    </Wraper>
  );
};

export default index;
