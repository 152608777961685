import React, { ReactChildren } from 'react';
import styled, { css } from 'styled-components';

const Wraper = styled.div`
  display: inline-block;
  width: 100%;
  height: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;

  &:hover #temp {
    display: flex;
  }
`;
const Content = styled.div<{ $direction?: 'top' | 'bottom' }>`
  position: absolute;
  display: none;
  width: max-content;
  max-width: 200px;
  border-radius: ${({ theme }) => theme.radius.CONTENT};
  padding: 5px 12px;
  font-size: 0.8em;
  background: ${({ theme }) => theme.bg.TERTIARY};
  border: 2px solid ${({ theme }) => theme.separator.OPAQUE};
  justify-content: center;
  align-items: center;

  ${(props) => {
    if (props?.$direction === 'bottom')
      return css`
        bottom: -50%;
      `;
    else
      return css`
        top: -50%;
      `;
  }}

  height: 30px;
`;

const Tooltip = ({
  children,
  title,
  direction,
  style,
}: {
  children: React.ReactNode;
  title?: string;
  direction?: 'top' | 'bottom';
  style?: React.CSSProperties | undefined;
}) => {
  return (
    <Wraper style={style}>
      {title && (
        <Content id="temp" $direction={direction}>
          {title}
        </Content>
      )}
      {children}
    </Wraper>
  );
};

export default Tooltip;
