import { DefaultValuesType } from './interfaces/defaultInterface';
import { API_PATH } from './constant';
import { ApiAgent } from './agent';
import { ElementalType } from './interfaces/elementalInterface';
import { WeaponType_Type } from './interfaces/weaponTypeInterface';

class DefaultAPI {
  private agent = new ApiAgent({ path: API_PATH.DEFAULT });
  private defaultValues: DefaultValuesType = {
    elementals: [],
    regions: [],
    weaponTypes: [],
    weaponAttributes: [],
    dows: [],
  };

  constructor() {
    this.getLocalStroage();
  }

  private saveLocalStroage = async (defaultValues: DefaultValuesType) => {
    const keys = Object.keys(defaultValues);

    keys.forEach((key) => {
      //@ts-ignore
      localStorage.setItem(key, JSON.stringify(this.defaultValues[key]));
    });
  };

  private getDefaultValues = async <T = DefaultValuesType>() => {
    const { data } = await this.agent.getter({});

    this.defaultValues = {
      ...data,
    };

    this.saveLocalStroage(data);
  };

  private getLocalStroage = async () => {
    const keys = Object.keys(this.defaultValues);
    let requireGetApi = false;

    keys.forEach((key) => {
      const data = localStorage.getItem(key);

      if (!data) return (requireGetApi = true);

      //@ts-ignore
      this.defaultValues[key] = JSON.parse(data);
    });

    if (requireGetApi) return this.getDefaultValues();
  };

  public get() {
    return this.defaultValues;
  }
}

export const defaultValues = new DefaultAPI();
