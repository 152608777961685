import React from 'react';
import styled, { css } from 'styled-components';
import { Button as _Button } from '@components/elements';
import { IMAGE_TAG } from '@constants';

import characterImage from '../../asset/images/character.png';
import todoImage from '../../asset/images/todo.png';
import settingImage from '../../asset/images/setting.png';
import weaponImage from '../../asset/images/weapon.png';
import homeImage from '../../asset/images/home.png';

const Button = styled(_Button)<{ $vertical?: boolean; $activate?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 12;

  justify-content: center;
  align-items: center;

  background-color: transparent;
  border-radius: 0;
  box-shadow: 0 0 0 0;
  font-size: large;
  border-radius: 0 0 1rem 1rem;

  opacity: 0.4;

  &:hover {
    background: ${({ theme }) => theme.bg.SECONDARY};

    box-shadow: 0 0 0 0;
    /* font-size: x-large; */

    opacity: 1;

    > img {
      transform: scale(0.8);
    }
  }

  ${(props) =>
    props?.$vertical &&
    css`
      flex-direction: column;
    `}

  ${(props) =>
    props.$activate &&
    css`
      opacity: 1;
      background: ${({ theme }) => theme.bg.PRIMARY};
      border-top: 3px solid black;

      > img {
        transform: scale(0.8);
      }
    `}
`;

const Image = styled.img`
  transform: scale(0.6);
  transition: 0.4s;
`;

type temp = keyof typeof IMAGE_TAG;
type ImageType = (typeof IMAGE_TAG)[temp];

const FooterButton = ({
  icon,
  activate,
  title,
  ...props
}: {
  icon: ImageType;
  activate?: boolean;
  title?: string;
  props?: React.HTMLProps<HTMLButtonElement>;
}) => {
  const selectImage = (icon: ImageType) => {
    let path = '';

    switch (icon) {
      case 'character':
        path = characterImage;
        break;
      case 'setting':
        path = settingImage;
        break;
      case 'todo':
        path = todoImage;
        break;
      case 'home':
        path = homeImage;
        break;
      case 'weapon':
        path = weaponImage;
        break;
      case 'weapon':
        path = homeImage;
        break;
    }

    return path;
  };

  return (
    <Button $activate={activate || false} {...props}>
      <Image src={selectImage(icon)} />
      <p>{title}</p>
    </Button>
  );
};

export default FooterButton;
