import React from 'react';
import styled, { css } from 'styled-components';
import { IoSearch } from 'react-icons/io5';

const Wraper = styled.div`
  height: 42px;
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.bg.TERTIARY};
  padding: 0 8px;
  border: 0.15rem solid ${({ theme }) => theme.bg.TERTIARY};
  border-radius: 1rem;

  &:hover {
    border: 0.15rem solid ${({ theme }) => theme.bg.SECONDARY};
  }
`;
const Input = styled.input`
  background: none;
  border: none;
  color: ${({ theme }) => theme.color.PRIMARY};
  margin-left: 8px;
  flex: 1;
  height: inherit;
  font-family: 'Jun';

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.SECONDARY};
  }
`;

const IconInput = ({
  value,
  onChange,
  iconHidden,
  placeHolder,
}: {
  value?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  iconHidden?: boolean;
  placeHolder?: string;
}) => {
  return (
    <Wraper>
      {!iconHidden && <IoSearch />}
      <Input
        placeholder={placeHolder ? placeHolder : 'Search'}
        onChange={onChange}
        value={value}
      />
    </Wraper>
  );
};

export default IconInput;
