import { CharacterType, CharactersType } from './interfaces/characInterface';
import { API_PATH } from './constant';
import { ApiAgent } from './agent';
import { Weapon, Weapons } from './interfaces/weapon.interface';

export class WeaponAPI {
  private agent = new ApiAgent({ path: API_PATH.WEAPON });

  constructor() {}

  public getWeaponById = async (id: number): Promise<Weapon> => {
    const { data } = await this.agent.getter({
      url: { routerName: '', value: id },
    });

    return data;
  };

  public getAllWeapons = async (): Promise<Weapons[]> => {
    const { data } = await this.agent.getter({});

    return data;
  };
}
