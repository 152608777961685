import React from 'react';
import styled from 'styled-components';
import PaimonImage from '../../asset/images/paimon.png';

const Wraper = styled.div`
  position: relative;
  width: max-content;
`;
const BgImage = styled.div`
  position: absolute;
  top: 30px;
  left: -30px;
  z-index: 1;
  opacity: 0.2;
`;

const Logo = () => {
  return (
    <Wraper>
      <p style={{ fontSize: '70px' }}>Paimon Diary</p>
      <p
        style={{
          fontSize: '50px',
          color: '#a9a9a9',
          textAlign: 'end',
          width: '100%',
        }}
      >
        Beta
      </p>
      <BgImage>
        <img src={PaimonImage} alt="" />
      </BgImage>
    </Wraper>
  );
};

export default Logo;
