import { CharacterType, CharactersType } from './interfaces/characInterface';
import { API_PATH } from './constant';
import { ApiAgent } from './agent';

export class CharacterAPI {
  private agent = new ApiAgent({ path: API_PATH.CHARACTER });

  constructor() {}

  // private mediatePaths = (data: CharacterType): CharacterType => {
  //   return {
  //     ...data,
  //     img_path: API_PATH.ROOT + data.img_path,
  //     avatar_img_path: API_PATH.ROOT + data.avatar_img_path,
  //   };
  // };

  public getCharacterById = async (id: number): Promise<CharacterType> => {
    const { data } = await this.agent.getter({
      url: { routerName: '', value: id },
    });

    return data;
  };

  public getAllCharacters = async (): Promise<CharactersType[]> => {
    const { data } = await this.agent.getter({});

    // const result: CharactersType[] = [];

    // data.forEach((character: CharactersType) => {
    //   result.push(this.mediatePaths(character));
    // });

    return data;
  };
}
