import React from 'react';
import styled from 'styled-components';
import ResetTodoList from './ResetTodoList';
import Credit from './Credit';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.padding.LARGE};
  align-items: center;

  > div {
    margin-bottom: 30px;
  }
`;

const index = () => {
  return (
    <Wraper>
      <ResetTodoList />
      <Credit />
    </Wraper>
  );
};

export default index;
