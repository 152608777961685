import { Button } from '@components/elements';
import React from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;

  > div + div {
  }
`;

const DowTab = styled.div`
  display: flex;

  > div {
    width: 20%;
  }

  > div + div {
    margin-left: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    > div {
      background-color: ${({ theme }) => theme.cement.PRIMARY.DEFAULT};
      border-radius: 1.5rem;
      padding: 3px 6px;
      cursor: pointer;
    }
  }
`;

const TodoManagerBtns = () => {
  const dow = ['일', '월', '화', '수', '목', '금', '토'];
  const buttons = ['+1', '+2', '+3', '+4', '+5', '+6', '+1주', '+2주', '1달'];

  return (
    <Wraper>
      <div style={{ fontSize: 'x-large' }}>주기</div>
      <div>
        <DowTab>
          <div>
            <p>시작 요일</p>
          </div>
          <div>
            {dow.map((data) => (
              <div>{data}</div>
            ))}
          </div>
        </DowTab>
        {buttons.map((data) => (
          <Button $size="small">{data}</Button>
        ))}
      </div>
    </Wraper>
  );
};

export default TodoManagerBtns;
