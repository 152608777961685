import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Week from '../Calendar/Week';
import TodoDayColumn from './TodoDayColumn';
import WeekCalendarModal from './WeekCalendarModal';
import { TodoType } from '@types';
import { defaultValues } from '@apis/defaultValues';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: ${({ theme }) => theme.radius.APP};
  overflow: hidden;
  border: 3px solid ${({ theme }) => theme.separator.OPAQUE};

  > div {
    height: max-content;
  }
  > div + div {
    flex: 1;
    overflow: hidden;
  }
`;

const Header = styled.div`
  padding: ${({ theme }) => theme.padding.SMALL} 0;
  border-bottom: 3px solid ${({ theme }) => theme.separator.OPAQUE};
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.bg.SECONDARY};

  > div {
    min-width: 14%;
    flex: 1;
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.separator.OPAQUE};
    &:first-of-type {
      border-left: none;
    }
  }
`;

const index = ({
  todayId,
  todoList,
  onDelete,
  addTodo,
}: {
  todayId: number;
  todoList: TodoType[];
  onDelete?: (todo: TodoType) => void;
  addTodo?: (todo: TodoType) => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDowId, setSelectedDowId] = useState(0);
  const dows = defaultValues.get().dows;

  return (
    <Wraper>
      <Header>
        <Week
          dowList={[
            '월요일',
            '화요일',
            '수요일',
            '목요일',
            '금요일',
            '토요일',
            '일요일',
          ]}
          days={[]}
        />
      </Header>
      <Content>
        {dows.map((data) => (
          <div key={data.name}>
            <TodoDayColumn
              todayId={todayId}
              columnId={data.id}
              todoList={todoList}
              onClick={() => {
                setSelectedDowId(data.id);
                setOpenModal(true);
              }}
              onDelete={onDelete}
            />
          </div>
        ))}
      </Content>
      <WeekCalendarModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        addTodo={addTodo}
        dowId={selectedDowId}
      />
    </Wraper>
  );
};

export default index;
