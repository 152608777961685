import IconButton from '@components/inputs/IconButton';
import React from 'react';
import styled from 'styled-components';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';

const Wraper = styled.div`
  display: flex;
  justify-content: center;
`;

const TodayHeader = () => {
  return (
    <Wraper>
      {/* <IconButton>
        <FaArrowLeft />
      </IconButton> */}
      <div>
        <p style={{ fontSize: 'xx-large' }}>Today</p>
      </div>
      {/* <IconButton>
        <FaArrowRight />
      </IconButton> */}
    </Wraper>
  );
};

export default TodayHeader;
