import { CharactersType } from '@apis/interfaces/characInterface';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoCloseSharp } from 'react-icons/io5';
import InfomationTab from './components/InfomationTab';
import { WeaponAPI } from '@apis/weapon';
import { Weapon } from '@apis/interfaces/weapon.interface';
import { Box } from '@components/frame';
// import WeaponTab from './WeaponTab';

const Wraper = styled.div`
  width: inherit;
  height: inherit;
`;

const ContentWarper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${({ theme }) => theme.padding.LARGE};

  > div {
    margin-bottom: 30px;
  }
`;

const CloseButton = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  left: -50px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.separator.OPAQUE};
  border-radius: 1rem 0 0 1rem;
`;

const DetailWeapon = ({
  selectedId,
  setSelectedId,
}: {
  selectedId: number | undefined;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const [weapon, setWeapon] = useState<Weapon | undefined>(undefined);
  const weaponApi = new WeaponAPI();

  const getWeapon = async (id: number) => {
    const data = await weaponApi.getWeaponById(id);

    setWeapon(data);
  };

  useEffect(() => {
    if (selectedId) getWeapon(selectedId);
  }, [selectedId]);

  return (
    <Wraper>
      <ContentWarper>
        <div>
          <InfomationTab infomation={weapon} />
        </div>
        <div style={{ fontSize: 'medium' }}>
          <Box style={{ color: '#a0a0a0' }}>{weapon?.infomations.describe}</Box>
        </div>

        <div
          style={{ fontSize: 'xx-large', color: 'white' }}
          dangerouslySetInnerHTML={{ __html: weapon?.infomations.skill || '' }}
        ></div>

        {/* Close Button */}
        {selectedId && (
          <CloseButton
            onClick={() => {
              setSelectedId(undefined);
            }}
          >
            <IoCloseSharp style={{ width: '50px', height: '50px' }} />
          </CloseButton>
        )}
      </ContentWarper>
    </Wraper>
  );
};

export default DetailWeapon;
