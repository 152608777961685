import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@components/frame';
import WeaponList from './WeaponList';
import { CharacterAPI } from '@apis/character';
import { CharactersType } from '@apis/interfaces/characInterface';
import { Weapon } from '@apis/interfaces/weapon.interface';
import DetailWeapon from './DetailWeapon';

const Wrapper = styled(Box)<{ selected?: boolean }>`
  position: relative;

  > div + div {
    height: 100%;
  }
`;

const InfoWraper = styled.div<{ open?: boolean }>`
  --infomation-width: 649px;
  position: absolute;
  width: var(--infomation-width);
  height: 100%;
  border-left: 0.2rem solid ${({ theme }) => theme.separator.OPAQUE};
  transition: 0.3s;
  translate: 100vw;
  ${(props) =>
    props.open &&
    css`
      translate: calc(100vw - var(--infomation-width));
    `}
`;

const index = () => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

  return (
    <Wrapper $direction="row" selected={selectedId !== undefined}>
      <WeaponList selectedId={selectedId} setSelectedId={setSelectedId} />
      <InfoWraper open={selectedId !== undefined}>
        <DetailWeapon selectedId={selectedId} setSelectedId={setSelectedId} />
      </InfoWraper>
    </Wrapper>
  );
};

export default index;
