import { Box } from '@components/frame';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageCard, { ImageCardType } from './ImageCard';

const Wraper = styled(Box)`
  overflow-y: scroll;
  flex-wrap: wrap;
  padding: 20px;

  > div {
    margin-right: 20px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

export type FilterType = {
  name: string;
  star: number[];
  elemental: number[];
  weaponType: number[];
  weaponAttribute?: number[];
};

type SortingType = 'starASC' | 'starDESC';

const ImageTable = ({
  children,
  list,
  displayStars,
  displayElemental,
  initFilterValue,
  filter,
  setSelectedId,
  sort,
}: {
  children?: React.ReactNode;
  list?: ImageCardType[];
  displayStars?: boolean;
  displayElemental?: boolean;
  initFilterValue?: FilterType;
  filter?: FilterType;
  setSelectedId: (id: number) => void;
  sort?: SortingType;
}) => {
  const [first, setFirst] = useState(true);
  const [localFilter, setLocalFilter] = useState<FilterType>({
    name: '',
    star: [],
    elemental: [],
    weaponType: [],
    weaponAttribute: [],
  });
  const [display, setDisplay] = useState<ImageCardType[]>([]);
  const [selected, setSelected] = useState<ImageCardType | undefined>(
    undefined,
  );

  const sorting = (sort: SortingType, currentList: ImageCardType[]) => {
    const newList = [...currentList];

    if (sort === 'starASC')
      return newList.sort((a, b) => {
        return b.star - a.star;
      });
    else
      return newList.sort((a, b) => {
        return a.star - b.star;
      });
  };

  const filteringName = (name: string, currentList: ImageCardType[]) => {
    let result = [...currentList];
    if (!name) return result;
    else result = result.filter((data) => data?.name.includes(name));

    return result;
  };

  const filteringStar = (stars: number[], currentList: ImageCardType[]) => {
    if (!stars.length) return currentList;
    else {
      let result = [...currentList];

      result = result.filter((data) => {
        for (let s of stars) {
          if (s === data.star) return data;
        }
      });
      return result;
    }
  };

  const filteringElemental = (
    elementals: number[],
    currentList: ImageCardType[],
  ) => {
    if (!elementals.length) return currentList;
    else {
      let result = [...currentList];

      result = result.filter((data) => {
        for (let e of elementals) {
          if (e === data.elemental) return data;
        }
      });
      return result;
    }
  };

  const filteringWeaponType = (
    weaponTypes: number[],
    currentList: ImageCardType[],
  ) => {
    if (!weaponTypes.length) return currentList;
    else {
      let newList = [...currentList];

      newList = newList.filter((data) => {
        for (let w of weaponTypes) {
          if (w === data.weapon_type) return data;
        }
      });
      return newList;
    }
  };

  const filteringWeaponAttribute = (
    weaponAttribute: number[],
    currentList: ImageCardType[],
  ) => {
    if (!weaponAttribute.length) return currentList;
    else {
      let newList = [...currentList];

      newList = newList.filter((data) => {
        for (let w of weaponAttribute) {
          if (w === data.weaponAttribute) return data;
        }
      });
      return newList;
    }
  };

  const filtering = (newFilter: FilterType) => {
    let filter = newFilter;

    if (first && initFilterValue) {
      filter = initFilterValue;
      setFirst(false);
    }

    if (!list?.length) {
      setDisplay([]);
      return;
    }

    let newList = [...list];

    if (sort) newList = sorting(sort, newList);

    newList = filteringName(filter.name, newList);
    newList = filteringStar(filter.star, newList);

    if (filter.elemental?.length)
      newList = filteringElemental(filter.elemental, newList);

    newList = filteringWeaponType(filter.weaponType, newList);

    if (filter?.weaponAttribute?.length)
      newList = filteringWeaponAttribute(filter?.weaponAttribute, newList);

    setDisplay(newList);
    setLocalFilter(filter);
  };

  const clickCard = (data: ImageCardType) => {
    setSelected(data);
    setSelectedId(data.id);
  };

  useEffect(() => {
    if (filter) setLocalFilter(filter);
  }, [filter]);

  useEffect(() => {
    filtering(localFilter);
  }, [localFilter, list]);

  return (
    <Wraper $direction="row">
      {display?.map((data) => (
        <ImageCard
          key={data.id + data.src}
          data={data}
          displayStars={displayStars}
          onClick={() => clickCard(data)}
          seleted={data.id === selected?.id}
          displayElemental={displayElemental}
        />
      ))}
      {children && children}
    </Wraper>
  );
};

export default ImageTable;
