import React, { useState } from 'react';
import { Box, Card } from '@components/frame';
import ContentWithTitle from '@components/layouts/ContentWithTitle';
import styled from 'styled-components';
import { Button } from '@components/elements';
import CustomModal from '@components/utils/Modal';
import { todoManager } from '@services/todoManager';
import { enqueueSnackbar } from 'notistack';

const Wraper = styled(Card)`
  width: 40%;
  height: max-content;
  padding: ${({ theme }) => theme.padding.MEDIUM};
`;

const ResetTodoList = () => {
  const [openModal, setOpenModal] = useState(false);

  const clearTodoList = () => {
    enqueueSnackbar({
      message: '데이터가 초기화 되었습니다.',
      variant: 'success',
    });
    todoManager.clearTodo();
    setOpenModal(false);
  };

  return (
    <Wraper>
      <ContentWithTitle title="데이터 초기화" fontSize="large" directColumn>
        <span>해당 데이터를 초기화 시, 저장되어 있던 데이터가 소멸합니다.</span>
        <Button
          $color="error"
          style={{ marginLeft: 'auto', marginTop: 'auto' }}
          $size="medium"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          초기화
        </Button>
      </ContentWithTitle>

      <CustomModal open={openModal}>
        <div>
          <p style={{ fontSize: 'x-large' }}>
            정말로 초기화를 진행 하시겠습니까?
          </p>
          <Box
            $direction="row"
            style={{ marginTop: '40px', justifyContent: 'space-between' }}
          >
            <Button
              $size="medium"
              onClick={() => {
                clearTodoList();
              }}
            >
              네
            </Button>
            <Button
              $color="error"
              $size="medium"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              아니요
            </Button>
          </Box>
        </div>
      </CustomModal>
    </Wraper>
  );
};

export default ResetTodoList;
