import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Header from './Header';
import Content from './Content';

const Wraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: ${({ theme }) => theme.radius.PAGE};

  > div {
    height: max-content;
  }
  > div + div {
    flex: 1;
  }
`;

export type DayType = {
  date: number;
  isToday?: boolean;
  isThisMonth?: boolean;
};

export type WeekType = {
  week: number;
  days: DayType[];
};

type CalendarType = {
  year: number;
  month: number;
  week: WeekType[];
  //   day: DayType;
};

const index = () => {
  const [calendar, setCalendar] = useState<CalendarType>({
    year: moment().year(),
    month: moment().month(),
    week: [],
  });

  const createCalendarDates = (
    setYear: number,
    setMonth: number,
  ): DayType[] => {
    const standard = moment([setYear, setMonth]);
    let month = {
      thisYear: standard.year(),
      thisMonth: standard.month(),
      today: { month: moment().month(), date: moment().date() },
      startOfDay: standard.startOf('month').day(),
      endOfDate: standard.endOf('month').date(),
      endOfDay: standard.endOf('month').day(),
    };
    let result = [];
    let date = 1;

    // 한달에 최대로 나올 수 있는 행의 갯수는 6개 이다.
    for (let i = 0; i < 6 * 7; i++) {
      if (i === 5 * 7 && month.thisMonth !== setMonth) break;

      if (i < month.startOfDay) {
        const idx = moment([month.thisYear, month.thisMonth])
          .startOf('month')
          .add(i - month.startOfDay, 'days')
          .format('DD');

        result.push({ date: +idx, isToday: undefined, isThisMonth: undefined });
      } else {
        result.push({
          date: date,
          isToday:
            date === month.today.date && month.thisMonth === month.today.month
              ? true
              : undefined,
          isThisMonth: month.thisMonth === setMonth ? true : undefined,
        });
        date++;
      }

      if (date > month.endOfDate) {
        date = 1;
        month.thisMonth += 1;
      }
    }

    return result;
  };

  const dayToWeek = (days: DayType[]) => {
    const result = [];
    let weekNum = 1;
    let count = 1;
    let weekDays = [];

    for (let d of days) {
      weekDays.push(d);
      count++;

      if (count > 7) {
        const week: WeekType = {
          week: weekNum,
          days: weekDays,
        };

        result.push(week);

        count = 1;
        weekNum++;
        weekDays = [];
      }
    }
    return result;
  };

  useEffect(() => {
    const days = createCalendarDates(calendar.year, calendar.month);
    const week = dayToWeek(days);
    setCalendar((prev) => ({ ...prev, week }));
  }, []);

  // useEffect(() => {
  //   console.log(moment().month());
  //   console.log(new Date());
  // }, [calendar]);

  return (
    <Wraper>
      <div>
        <Header year={calendar.year} month={calendar.month} />
      </div>
      <div>
        <Content week={calendar.week} />
      </div>
    </Wraper>
  );
};

export default index;
