export const IMAGE_TAG = {
  HOME: 'home',
  // TODO: 'todo',
  CHARACTER: 'character',
  WEAPON: 'weapon',
  SETTING: 'setting',
} as const;

export const PATH = {
  HOME: {
    URL: '/',
    NAME: '홈',
    ICON: IMAGE_TAG.HOME,
  },
  // TODO: {
  //   URL: '/todo',
  //   NAME: '할 일',
  //   ICON: IMAGE_TAG.TODO,
  // },
  WEAPON: {
    URL: '/weapon',
    NAME: '무기',

    ICON: IMAGE_TAG.CHARACTER,
  },
  CHARACTER: {
    URL: '/character',
    NAME: '캐릭터',

    ICON: IMAGE_TAG.CHARACTER,
  },
  SETTING: {
    URL: '/setting',
    NAME: '설정',

    ICON: IMAGE_TAG.SETTING,
  },
};

export const WEAPON_TYPE_ENUM = {
  unknown: 1,
  Sword: 2,
  Claymore: 3,
  Bow: 4,
  Catalyst: 5,
  Polearm: 6,
};
