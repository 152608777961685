import React from 'react';
import styled, { css } from 'styled-components';
import { DayType } from '.';

const Wraper = styled.div<{
  $center?: boolean;
  $bgDisable?: boolean;
  $direction?: 'row';
  $selected?: boolean;
  $thisMonth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-width: 14%;
  height: 100%;
  background: ${({ theme }) => theme.bg.SECONDARY};
  border-radius: ${({ theme }) => theme.radius.APP};
  padding: 7px 10px;
  cursor: pointer;

  > div {
  }
  > div + div {
  }

  &:hover {
    background: ${({ theme }) => theme.bg.PRIMARY};
  }

  ${(props) =>
    props?.$center &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props?.$bgDisable &&
    css`
      background: transparent;
    `}
  ${(props) =>
    props?.$direction &&
    css`
      flex-direction: row;
    `}
  
    ${(props) =>
    props?.$selected &&
    css`
      border: 2px solid ${({ theme }) => theme.separator.OPAQUE};
    `}

    ${(props) =>
    !props?.$thisMonth &&
    !props?.$bgDisable &&
    css`
      background: ${({ theme }) => theme.bg.PRIMARY};
    `}
`;

const DateTab = styled.div<{ $thisMonth?: boolean; $selected?: boolean }>`
  ${(props) =>
    !props?.$thisMonth &&
    css`
      color: ${({ theme }) => theme.color.fontColor.TERTIARY};
    `}

  ${(props) =>
    props?.$selected &&
    css`
      color: white;
      background-color: red;
      padding: 2px 7px;
      border-radius: 1.5rem;
      width: fit-content;
    `}
`;

const Day = ({
  day,
  center,
  bgDisable,
  direction,
  disableContent,
  children,
}: {
  day?: DayType;
  center?: boolean;
  bgDisable?: boolean;
  direction?: 'row';
  disableContent?: boolean;
  children?: React.ReactNode;
}) => {

  return (
    <Wraper
      $center={center}
      $bgDisable={bgDisable}
      $direction={direction}
      $selected={day?.isToday}
      $thisMonth={day?.isThisMonth}
    >
      <DateTab $thisMonth={day?.isThisMonth} $selected={day?.isToday}>
        {day?.date}
      </DateTab>
      {/* {!disableContent && <div>content</div>} */}
      {children}
    </Wraper>
  );
};

export default Day;
