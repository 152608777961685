import styled, { css } from 'styled-components';
import { Link as _Link } from 'react-router-dom';

export const Button = styled.button<{
  $size?: 'small' | 'medium' | 'large';
  $color?: 'error';
}>`
  min-width: 65px;
  border-radius: ${({ theme }) => theme.radius.CONTENT};
  border: 0;
  background: ${({ theme }) => theme.cement.PRIMARY.DEFAULT};
  font-size: larger;
  font-weight: 600;
  color: ${({ theme }) => theme.color.PRIMARY};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.4s;
  /* padding: 5px; */
  &:hover {
    background: ${({ theme }) => theme.cement.PRIMARY.SECONDARY};
    cursor: pointer;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }

  ${(props) => {
    const size = props?.$size;

    if (size === 'small') return { padding: '6px' };
    else if (size === 'medium') return { padding: '10px' };
    else return { padding: '15px' };
  }}

  ${(props) =>
    props?.$color &&
    css`
      background: ${({ theme }) => theme.cement.NEGATIVE.DEFAULT};

      &:hover {
        background: ${({ theme }) => theme.cement.NEGATIVE.SECONDARY};
      }
    `}
`;

export const Link = styled(_Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.color.PRIMARY};
`;
