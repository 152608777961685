import Tooltip from '@components/Tooltip';
import { Box } from '@components/frame';
import React from 'react';
import styled, { css } from 'styled-components';

const Wraper = styled(Box)<{ $checked?: boolean }>`
  width: 38px;
  height: 38px;
  border: 0.1rem solid ${({ theme }) => theme.separator.OPAQUE};
  border-radius: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadow.NEGATIVE_SPREAD};
  opacity: 0.8;
  transition: 0.25s;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.bg.TERTIARY};
  }

  ${(props) =>
    props?.$checked &&
    css`
      background: red;
    `}
`;

const RoundButton = ({
  src,
  onClick,
  checked,
  title,
  direction,
  style,
}: {
  src: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  checked?: boolean;
  title?: string;
  direction?: 'top' | 'bottom';
  style?: React.CSSProperties | undefined;
}) => {
  return (
    <Wraper onClick={onClick} $checked={checked} style={style}>
      <Tooltip title={title} direction={direction}>
        <img
          crossOrigin="anonymous"
          src={src}
          alt=""
          style={{ width: '100%', height: '100%' }}
        />
      </Tooltip>
    </Wraper>
  );
};

export default RoundButton;
