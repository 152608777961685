import React from 'react';
import styled, { css } from 'styled-components';
import { Frame } from './frame';
import Tooltip from './Tooltip';

const Wraper = styled(Frame)<{ $customBg?: string }>`
  background: ${({ theme }) => theme.bg.SECONDARY};

  ${(props) =>
    props.$customBg &&
    css`
      background: ${props.$customBg};
    `}
`;

const ImageBox = ({
  children,
  toolTipTitle,
  customBg,
  direction,
}: {
  children: React.ReactNode;
  toolTipTitle?: string;
  customBg?: string;
  direction?: 'bottom' | 'top';
}) => {
  return (
    <Wraper $customBg={customBg}>
      <Tooltip title={toolTipTitle} direction={direction}>
        {children}
      </Tooltip>
    </Wraper>
  );
};

export default ImageBox;
