import { ItemType } from '@apis/interfaces/itemInterface';
import ImageBox from '@components/ImageBox';
import ImgCard from '@components/ImgCard';
import { Box } from '@components/frame';
import React from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: ${({ theme }) => theme.radius.CONTENT};
  > div {
    font-size: large;
  }
`;

const ItemCard = styled(Box)`
  margin-top: 15px;
  justify-content: space-between;
  background: transparent;

  > div {
    margin-left: 0;
  }
  > div + div {
    margin-left: 10px;
  }
`;

const ItemBox = ({ title, items }: { title: string; items: ItemType[] }) => {
  return (
    <Wraper>
      <div>{title}</div>
      <div>
        <ItemCard $direction="row">
          {items.map((data) => (
            <ImageBox toolTipTitle={data.name} key={data.id + data.name}>
              <ImgCard src={data.img_path} star={0} />
            </ImageBox>
          ))}
        </ItemCard>
      </div>
    </Wraper>
  );
};

export default ItemBox;
