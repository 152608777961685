import React from 'react';
import styled, { css } from 'styled-components';

const Wraper = styled.div<{ $starLevel: number }>`
  height: 100%;
  width: 100%;
  transition: 0.4s;

  ${(props) =>
    css`
      background-color: ${({ theme }) => {
        if (props.$starLevel === 5) return theme.starColor.FIVE;
        else if (props.$starLevel === 4) return theme.starColor.FOUR;
        else theme.starColor.ONE;
      }};
    `}
`;

const ImgCard = ({ src, star }: { src: string; star: number }) => {
  return (
    <Wraper $starLevel={star}>
      <img
        crossOrigin="anonymous"
        src={src}
        alt=""
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </Wraper>
  );
};

export default ImgCard;
