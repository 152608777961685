import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@components/frame';
import IconInput from '@components/inputs/IconInput';
import StarButton from '@components/inputs/StarButton';
import DefaultValuesComponent from '@components/DefaultValuesComponent';
import { FilterType } from './ImageTable';

const Wraper = styled(Box)`
  width: 100%;
  height: max-content;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.separator.OPAQUE};

  > div {
    width: fit-content;
  }

  > div + div {
    margin-left: 30px;
    flex: 1;
  }
`;

const StarTab = styled(Box)`
  margin-top: 20px;
  height: max-content;

  > div {
    margin-right: 10px;
  }
`;

const FilterTab = styled(Box)`
  height: max-content;
  margin-top: 10px;

  > div {
    margin-right: 6px;
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

const FilterBox = ({
  filter,
  setFilter,
  starButtons,
  showElementals,
  showWeaponAttri,
  showWeaponType,
}: {
  filter?: FilterType;
  setFilter?: React.Dispatch<React.SetStateAction<FilterType>>;
  starButtons?: number[];
  showElementals?: boolean;
  showWeaponAttri?: boolean;
  showWeaponType?: boolean;
}) => {
  const [checkedStars, setCheckedStars] = useState<number[]>([]);
  const [checkedElemental, setCheckedElemental] = useState<number[]>([]);
  const [checkedWeaponType, setCheckedWeaponType] = useState<number[]>([]);
  const [checkedAttribute, setCheckedAttribute] = useState<number[]>([]);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (setFilter)
      setFilter((props) => {
        return { ...props, name: value };
      });
  };

  const onToggleState = (
    num: number,
    arr: number[],
    type: 'star' | 'elemental' | 'weaponType' | 'weaponAttribute',
  ) => {
    let currentStars = [...arr];

    if (currentStars.indexOf(num) > -1)
      currentStars = currentStars.filter((data) => data !== num);
    else currentStars.push(num);

    if (setFilter)
      setFilter((props) => {
        return { ...props, [type]: currentStars };
      });

    if (type === 'star') setCheckedStars(currentStars);
    if (type === 'elemental') setCheckedElemental(currentStars);
    if (type === 'weaponType') setCheckedWeaponType(currentStars);
    if (type === 'weaponAttribute') setCheckedAttribute(currentStars);
  };

  return (
    <Wraper $direction="row">
      <div>
        <IconInput onChange={onChangeName} value={filter?.name} />
        {starButtons?.length && (
          <StarTab $direction="row">
            {starButtons.map((data) => (
              <StarButton
                key={data}
                num={data}
                checked={
                  !!checkedStars.find((checked) => checked === data) || false
                }
                onClick={() => {
                  onToggleState(data, checkedStars, 'star');
                }}
              />
            ))}
          </StarTab>
        )}
      </div>
      <div>
        {showElementals && (
          <FilterTab $direction="row">
            <DefaultValuesComponent
              checkedIds={checkedElemental}
              onClick={(id) => {
                onToggleState(id, checkedElemental, 'elemental');
              }}
              elemental
            />
          </FilterTab>
        )}
        {showWeaponAttri && (
          <FilterTab $direction="row">
            <DefaultValuesComponent
              checkedIds={checkedAttribute}
              onClick={(id) => {
                onToggleState(id, checkedAttribute, 'weaponAttribute');
              }}
              weaponAttri
            />
          </FilterTab>
        )}
        {showWeaponType && (
          <FilterTab $direction="row">
            <DefaultValuesComponent
              checkedIds={checkedWeaponType}
              onClick={(id) => {
                onToggleState(id, checkedWeaponType, 'weaponType');
              }}
              weaponType
              direction="bottom"
            />
          </FilterTab>
        )}
      </div>
    </Wraper>
  );
};

export default FilterBox;
