const BACKGROUND = {
  PRIMARY: '#29292d',
  SECONDARY: '#2C2C2E',
  TERTIARY: '#3A3A3C',
};

const RADIUS = {
  APP: '15px',
  CONTENT: '10px',
  PAGE: '20px',
};

const PADDING = {
  TINY: '5px',
  SMALL: '10px',
  REGULAR: '15px',
  MEDIUM: '20px',
  LARGE: '30px',
};

const SEPARATOR = {
  OPAQUE: '#545458',
  POINT: '#483a88',
};

const FONT = {
  fontColor: {
    DEFAULT: '#f1f1f1',
    SECONDARY: '#9a9a9a',
    TERTIARY: '#4e4e4e',
  },
  PRIMARY: '#F6F6F6',
  SECONDARY: '#B8B8B8',
};

const CEMENT = {
  PRIMARY: { DEFAULT: '#1c7095', SECONDARY: '#1f9ad0' },
  POSITIVE: { DEFAULT: '#6BB072', SECONDARY: '#6CB089', THRTIARY: '#6BA9B0' },
  NEGATIVE: { DEFAULT: '#801919', SECONDARY: '#D47171', THRTIARY: '#551F1F' },
};

const PRIMARY = {
  MAIN: '#A55FEB',
  DARK: '#8251B4',
  LIGHT: '#B77FEF',
};

const SHADOW = {
  BLUR: '6px 6px 20px #000',
  NEGATIVE_SPREAD: '0 10px 10px -5px #000',
};

const START_COLOR = {
  FIVE: '#ad6f38',
  FOUR: '#60539d',
  THREE: '#567698',
  TWO: '#4b6f68',
  ONE: '#6c6f75',
};

const TRANSITION = {
  DEFAULT: '0.4s',
};

//#474A5E

const frame = {
  focusColor: PRIMARY,
  cement: CEMENT,
  color: FONT,
  separator: SEPARATOR,
  padding: PADDING,
  radius: RADIUS,
  bg: BACKGROUND,
  shadow: SHADOW,
  starColor: START_COLOR,
  delay: TRANSITION,
};

export const DESIGN_PATTERN = {
  ...frame,
};

export type DESIGN_PATTERN_TYPE = typeof DESIGN_PATTERN;
