import React, { useState, useEffect } from 'react';
import CustomModal from '@components/utils/Modal';
import { Button } from '@components/elements';
import ImageTable from '@components/layouts/ImageTable';
import styled from 'styled-components';
import { WEAPON_TYPE_ENUM } from '@constants';

const Wraper = styled.div`
  width: 1394px;
  height: 77vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div {
    height: max-content;
  }
  > div + div {
    flex: 1;
    overflow-y: scroll;
  }
  > div + div + div {
    height: max-content;
    flex: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 20px;
  }
`;

const Title = styled.h1`
  border-bottom: 2px solid ${({ theme }) => theme.separator.OPAQUE};
  padding-bottom: ${({ theme }) => theme.padding.MEDIUM};
`;

const WeaponModal = ({
  open,
  filterType,
  weapons,
  onApply,
  onCancle,
}: {
  open: boolean;
  filterType?: number;
  weapons?: ImageCardType[];
  onApply?: (weaponId: number) => void;
  onCancle?: () => void;
}) => {
  const [type, setType] = useState<number[]>([]);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    if (filterType) setType([filterType]);
  }, [filterType]);

  return (
    <CustomModal open={open}>
      <Wraper>
        <div>
          <Title>무기</Title>
        </div>
        <div>
          <ImageTable
            displayStars
            list={weapons}
            initFilterValue={{
              elemental: [],
              weaponType: type,
              name: '',
              star: [],
            }}
            setSelectedId={(id) => {
              setSelectedId(id);
            }}
            sort="starASC"
          ></ImageTable>
        </div>
        <div>
          <Button
            onClick={() => {
              if (onApply) onApply(selectedId);
            }}
          >
            선택
          </Button>
          <Button
            onClick={() => {
              if (onCancle) onCancle();
            }}
            style={{ marginLeft: '20px' }}
          >
            취소
          </Button>
        </div>
      </Wraper>
    </CustomModal>
  );
};

export default WeaponModal;
