import { defaultValues } from '@apis/defaultValues';
import Tooltip from '@components/Tooltip';
import ContentWithTitle from '@components/layouts/ContentWithTitle';
import ImageCard from '@components/layouts/ImageCard';
import { TodoType } from '@types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  width: 100%;
  height: 100%;

  > div {
    margin-top: 20px;

    &:first-of-type {
      margin-top: 10px;
    }
  }
`;

const TodayContent = ({
  todayId,
  todoList,
}: {
  todayId: number;
  todoList: TodoType[];
}) => {
  const [characterTodos, setCharacterTodos] = useState<TodoType[]>([]);
  const [weaponTodos, setWeaponTodos] = useState<TodoType[]>([]);
  const [customTodos, setCustomTodos] = useState<TodoType[]>([]);

  const divideTodos = (
    list: TodoType[],
    filterType: 'character' | 'weapon' | 'custom',
    setList: React.Dispatch<React.SetStateAction<TodoType[]>>,
  ) => {
    const result: TodoType[] = [];

    for (let t of list) {
      if (t.type === filterType) result.push(t);
    }

    setList(result);
  };

  const filterTodayList = (todoList: TodoType[]) => {
    const result: TodoType[] = [];

    todoList.forEach((data) => {
      if (data.dow) {
        data.dow.forEach((day) => {
          if (day === todayId) result.push(data);
        });
      }
    });

    return result;
  };

  useEffect(() => {
    const list = filterTodayList(todoList);
    divideTodos(list, 'character', setCharacterTodos);
    divideTodos(list, 'weapon', setWeaponTodos);
    divideTodos(list, 'custom', setCustomTodos);
  }, [todoList]);

  return (
    <Wraper>
      {!!characterTodos?.length && (
        <div>
          <ContentWithTitle title="캐릭터 특성" fontSize="large">
            {characterTodos.map((data) => (
              <Tooltip title={data.memo} style={{ width: 'fit-content' }}>
                <ImageCard
                  key={data.data.name}
                  data={data.data}
                  hiddenName
                  style={{ width: '84px', height: '84px' }}
                />
              </Tooltip>
            ))}
          </ContentWithTitle>
        </div>
      )}
      {!!weaponTodos?.length && (
        <div>
          <ContentWithTitle title="무기 돌파" fontSize="large">
            {weaponTodos.map((data) => (
              <Tooltip title={data.memo} style={{ width: 'fit-content' }}>
                <ImageCard
                  key={data.data.name}
                  data={data.data}
                  hiddenName
                  style={{ width: '84px', height: '84px' }}
                />
              </Tooltip>
            ))}
          </ContentWithTitle>
        </div>
      )}
      {!!customTodos?.length && (
        <div>
          <ContentWithTitle title="개인" fontSize="large">
            {customTodos.map((data) => (
              <Tooltip title={data.memo} style={{ width: 'fit-content' }}>
                <ImageCard
                  key={data.data.name}
                  data={data.data}
                  hiddenName
                  style={{ width: '84px', height: '84px' }}
                />
              </Tooltip>
            ))}
          </ContentWithTitle>
        </div>
      )}
    </Wraper>
  );
};

export default TodayContent;
