import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from './frame';
import { defaultValues } from '@apis/defaultValues';

const Wraper = styled(Box)<{ $checked?: boolean }>`
  width: 38px;
  height: 38px;
  border: 0.1rem solid ${({ theme }) => theme.separator.OPAQUE};
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadow.NEGATIVE_SPREAD};
  opacity: 0.8;
  transition: 0.25s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.bg.TERTIARY};
  }

  ${(props) =>
    props?.$checked &&
    css`
      background: red;
    `}
`;

const ElementalIcon = ({
  id,
  checked,
  onClick,
}: {
  id: number;
  checked?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const elementalList = defaultValues.get().elementals;

  const getElementalImgSrc = (id: number) => {
    const elemental = elementalList.find((elemental) => elemental.id === id);

    return elemental?.img_path;
  };

  return (
    <Wraper onClick={onClick} $checked={checked}>
      <img
        crossOrigin="anonymous"
        src={getElementalImgSrc(id)}
        alt=""
        style={{ width: '100%', height: '100%' }}
      />
    </Wraper>
  );
};

export default ElementalIcon;
