import { Box } from '@components/frame';
import React, { useState } from 'react';
import styled from 'styled-components';

import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropup } from 'react-icons/io';
import AddableButton from '@components/inputs/AddableButton';

const Wraper = styled(Box)<{ $open?: boolean }>`
  overflow: hidden;
  height: ${(props) => (props?.$open ? `fit-content` : `44px`)};
  transition: 0.3s;
`;

const NameTag = styled(Box)`
  height: max-content;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid ${({ theme }) => theme.separator.OPAQUE};
`;

const AddWeaponWraper = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.bg.TERTIARY};
  border-radius: 0 0 24px 24px;
`;

const AddableTab = ({
  title,
  children,
  onAddEvent,
}: {
  title?: string;
  children?: React.ReactNode;
  onAddEvent?: () => void;
}) => {
  const [openTab, setOpenTab] = useState(true);

  return (
    <Wraper $open={openTab}>
      <NameTag
        $direction="row"
        onClick={() => {
          setOpenTab(!openTab);
        }}
      >
        <span style={{ fontSize: 'x-large' }}>{title}</span>
        {openTab ? (
          <IoMdArrowDropup size="1.5em" />
        ) : (
          <IoMdArrowDropdown size="1.5em" />
        )}
      </NameTag>
      <AddWeaponWraper $center>
        {!children && (
          <AddableButton
            onClick={() => {
              if (onAddEvent) onAddEvent();
            }}
          />
        )}

        {children && children}
      </AddWeaponWraper>
    </Wraper>
  );
};

export default AddableTab;
