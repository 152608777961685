import React from 'react';
import styled from 'styled-components';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;

  > div + div {
  }
`;

const TodoManagerText = () => {
  return (
    <Wraper>
      <div>메모</div>
      <div>입력할 거</div>
    </Wraper>
  );
};

export default TodoManagerText;
