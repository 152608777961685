import React, { useEffect, useState } from 'react';
import { Box } from '@components/frame';
import styled from 'styled-components';
import WeaponModal from './components/WeaponModal';
import Tab from './components/AddableTab';
import { WeaponAPI } from '@apis/weapon';
import { Weapon, Weapons } from '@apis/interfaces/weapon.interface';
import { ImageCardType } from '@components/layouts/ImageCard';
import { CharactersType } from '@apis/interfaces/characInterface';
import DescribeWeapon from './components/DescribeWeapon';

const Wraper = styled(Box)``;

const WeaponTab = ({
  selectedCharacter,
  selectedWeapon,
  setSelectedWeapon,
}: {
  selectedCharacter: CharactersType | undefined;
  selectedWeapon?: Weapon;
  setSelectedWeapon: React.Dispatch<React.SetStateAction<Weapon | undefined>>;
}) => {
  const [weapons, setWeapons] = useState<Weapons[]>([]);
  const [displayWeapons, setDisplayWeapons] = useState<ImageCardType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const weaponService = new WeaponAPI();

  const ImageCardTypeMaker = (data: Weapons) => {
    const result: ImageCardType = {
      id: data.id,
      name: data.name,
      src: data.img_path,
      star: data.star,
      weapon_type: data.weapon_type,
    };

    return result;
  };

  const getWeapon = async (id: number) => {
    const data = await weaponService.getWeaponById(id);

    setSelectedWeapon(data);
  };

  const getWeaponList = async () => {
    const data = await weaponService.getAllWeapons();

    setWeapons(data);

    return data;
  };

  const onAddWeapon = async () => {
    const weapons = await getWeaponList();

    const displayList = weapons.map((data) => ImageCardTypeMaker(data));
    setDisplayWeapons(displayList);

    setOpenModal(true);
  };

  const onCloseModal = () => {
    setWeapons([]);
    setOpenModal(false);
  };

  const onApplyWeapon = async (id: number) => {
    await getWeapon(id);
    onCloseModal();
  };

  const resetState = () => {
    setWeapons([]);
  };

  const onChangeWeapon = () => {
    setSelectedWeapon(undefined);

    onAddWeapon();
  };

  useEffect(() => {
    resetState();
    setSelectedWeapon(undefined);
  }, [selectedCharacter]);

  return (
    <Wraper>
      <Tab title="무기" onAddEvent={onAddWeapon}>
        {selectedWeapon && (
          <DescribeWeapon weapon={selectedWeapon} onChange={onChangeWeapon} />
        )}
      </Tab>

      <WeaponModal
        open={openModal}
        weapons={displayWeapons}
        onCancle={onCloseModal}
        onApply={onApplyWeapon}
        filterType={selectedCharacter?.weapon_type}
      />
    </Wraper>
  );
};

export default WeaponTab;
