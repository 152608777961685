import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, Todo, Character, Weapon, Setting } from '@containers';
import Layout from './layouts/Layout';
import { ThemeProvider } from 'styled-components';
import { DESIGN_PATTERN } from './styles/theme';
import { PATH } from '@constants';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ThemeProvider theme={DESIGN_PATTERN}>
          <SnackbarProvider
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          />
          <Router>
            <Routes>
              <Route
                path={PATH.CHARACTER.URL}
                element={<Layout contents={<Character />} />}
              />
              <Route
                path={PATH.WEAPON.URL}
                element={<Layout contents={<Weapon />} />}
              />
              {/* <Route
                path={PATH.TODO.URL}
                element={<Layout contents={<Todo />} />}
              /> */}
              <Route
                path={PATH.SETTING.URL}
                element={<Layout contents={<Setting />} />}
              />
              <Route path="/*" element={<Layout contents={<Home />} />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}
