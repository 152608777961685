import { Box } from '@components/frame';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { DESIGN_PATTERN } from '../../styles/theme';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: DESIGN_PATTERN.bg.PRIMARY,
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    fontFamily: 'Jun',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
  },
};

const CustomModal = ({
  children,
  open,
  onClose,
}: {
  children: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
}) => {
  const [modelOpen, setModelOpen] = useState(false);

  useEffect(() => {
    setModelOpen(open || false);
  }, [open]);

  return (
    <Modal isOpen={modelOpen} style={customStyles} onAfterClose={onClose}>
      {children}
    </Modal>
  );
};

export default CustomModal;
