import { TodoType } from '@types';

class TodoModel {
  private localstorageKey = 'Todo';
  private todo: TodoType[] = [];

  constructor() {
    const saved = localStorage.getItem(this.localstorageKey);

    if (saved) this.todo = JSON.parse(saved);
  }

  private saveLocalStroage(list: TodoType[]) {
    localStorage.setItem(this.localstorageKey, JSON.stringify(list));
  }

  setTodo(newTodo: TodoType) {
    const duplicated = this.todo.find(
      (data) => data.data.name === newTodo.data.name,
    );

    if (duplicated) return;

    this.todo.push(newTodo);
    this.saveLocalStroage(this.todo);

    return this.todo;
  }

  getTodo() {
    return this.todo;
  }

  removeTodo(name: string) {
    const newTodo = this.todo.filter((data) => data.data.name !== name);

    this.todo = newTodo;
    this.saveLocalStroage(this.todo);

    return this.todo;
  }

  clearTodo() {
    this.todo = [];
    localStorage.removeItem(this.localstorageKey);
  }
}

export const todoManager = new TodoModel();
