import React from 'react';
import Modal from 'react-modal';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
const container = document.getElementById('root');
const root = createRoot(container as Element);

Modal.setAppElement('#root');

root.render(<App />);
