import { CharactersType } from '@apis/interfaces/characInterface';
import React from 'react';
import styled from 'styled-components';
import { IoCloseSharp } from 'react-icons/io5';
import InfomationChara from './InfomationChara';
import WeaponTab from './WeaponTab';
import { Weapon } from '@apis/interfaces/weapon.interface';

const Wraper = styled.div`
  width: inherit;
  height: inherit;
  position: relative;
`;

const ContentWarper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.padding.LARGE};
  overflow: hidden;

  > div {
    margin-bottom: 30px;
    width: 100%;
  }
`;

const CloseButton = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  left: -50px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.separator.OPAQUE};
  border-radius: 1rem 0 0 1rem;
`;

const DetailCharacter = ({
  selectedCharacter,
  setSelectedCharacter,
  selectedWeapon,
  setSelectedWeapon,
}: {
  selectedCharacter: CharactersType | undefined;
  setSelectedCharacter: React.Dispatch<
    React.SetStateAction<CharactersType | undefined>
  >;
  selectedWeapon?: Weapon;
  setSelectedWeapon: React.Dispatch<React.SetStateAction<Weapon | undefined>>;
}) => {
  return (
    <Wraper>
      <ContentWarper>
        <div>
          <InfomationChara
            selectedCharacter={selectedCharacter}
            setSelectedCharacter={setSelectedCharacter}
            selectedWeapon={selectedWeapon}
          />
        </div>
        <div>
          <WeaponTab
            selectedCharacter={selectedCharacter}
            selectedWeapon={selectedWeapon}
            setSelectedWeapon={setSelectedWeapon}
          />
        </div>
      </ContentWarper>
      {/* Close Button */}
      {selectedCharacter && (
        <CloseButton
          onClick={() => {
            setSelectedCharacter(undefined);
          }}
        >
          <IoCloseSharp style={{ width: '50px', height: '50px' }} />
        </CloseButton>
      )}
    </Wraper>
  );
};

export default DetailCharacter;
