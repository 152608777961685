import React from 'react';
import styled from 'styled-components';
import Week from './Week';
import { WeekType } from '.';

const Wraper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > div {
    height: fit-content;
    border-bottom: 1px solid ${({ theme }) => theme.separator.OPAQUE};
  }
  > div + div {
    padding: 5px;
    border-bottom: none;
    /* margin-top: 7px; */
    flex: 1;
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

const Content = ({ week }: { week: WeekType[] }) => {
  return (
    <Wraper>
      <div>
        <Week
          dowList={['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']}
          days={[]}
        />
      </div>
      <div>
        <div>
          {week.map((data) => (
            <Week key={data.week} days={data.days} />
          ))}
        </div>
      </div>
    </Wraper>
  );
};

export default Content;
