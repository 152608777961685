import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@components/frame';
import CharacterList from './CharacterList';
import { CharacterAPI } from '@apis/character';
import { CharactersType } from '@apis/interfaces/characInterface';
import DetailCharacter from './DetailCharacter';
import { Weapon } from '@apis/interfaces/weapon.interface';

const Wrapper = styled(Box)<{ selected?: boolean }>`
  position: relative;

  > div + div {
    height: 100%;
  }
`;

const InfoWraper = styled.div<{ open?: boolean }>`
  --infomation-width: 649px;
  position: absolute;
  width: var(--infomation-width);
  height: 100%;
  border-left: 0.2rem solid ${({ theme }) => theme.separator.OPAQUE};
  transition: 0.3s;
  translate: 100vw;
  ${(props) =>
    props.open &&
    css`
      translate: calc(100vw - var(--infomation-width));
    `}
`;

const index = () => {
  const [characters, setCharacters] = useState<CharactersType[]>([]);
  const [selectedCharacter, setSelectedCharacter] = useState<CharactersType>();
  const [selectedWeapon, setSelectedWeapon] = useState<Weapon>();
  const characterApi = new CharacterAPI();

  const getCharacters = async () => {
    const result = await characterApi.getAllCharacters();

    setCharacters(result);
  };

  useEffect(() => {
    getCharacters();
  }, []);

  return (
    <Wrapper $direction="row" selected={selectedCharacter !== undefined}>
      <CharacterList
        characters={characters}
        selectedCharacter={selectedCharacter}
        setSelectedCharacter={setSelectedCharacter}
      />
      <InfoWraper open={selectedCharacter !== undefined}>
        <DetailCharacter
          selectedCharacter={selectedCharacter}
          setSelectedCharacter={setSelectedCharacter}
          selectedWeapon={selectedWeapon}
          setSelectedWeapon={setSelectedWeapon}
        />
      </InfoWraper>
    </Wrapper>
  );
};

export default index;
