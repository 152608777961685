import React from 'react';
import filled from '../../asset/icons/star_filled.png';
import empty from '../../asset/icons/star_empty.png';
import styled from 'styled-components';
import { Frame } from '@components/frame';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const Wraper = styled(Frame)<{ $customBg?: string }>`
  width: 48px;
  height: 48px;
  background: ${({ theme }) => theme.bg.TERTIARY};
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background: red;
  }

  > img {
    width: 32px;
    height: 32px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

const FavoritButton = ({
  checked,
  onClick,
}: {
  checked?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Wraper
      onClick={() => {
        // if (message) enqueueSnackbar(message, { variant: 'success' });
        if (onClick) onClick();
      }}
    >
      <img src={checked ? filled : empty} alt="" />
    </Wraper>
  );
};

export default FavoritButton;
