import { Box } from '@components/frame';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CharactersType } from '@apis/interfaces/characInterface';
import FilterBox from '@components/layouts/FilterBox';
import ImageTable, { FilterType } from '@components/layouts/ImageTable';
import { ImageCardType } from '@components/layouts/ImageCard';

const Wraper = styled(Box)<{ selected?: boolean }>`
  width: 90vw;
  transition: 0.3s;

  ${(props) =>
    props?.selected &&
    css`
      width: calc(100vw - 649px);
    `}

  > div {
    height: max-content;
    padding: 1rem 1.3rem;
  }

  > div + div {
    height: fit-content;
  }
`;

const CharacterList = ({
  characters,
  selectedCharacter,
  setSelectedCharacter,
}: {
  characters: CharactersType[];
  selectedCharacter: CharactersType | undefined;
  setSelectedCharacter: React.Dispatch<
    React.SetStateAction<CharactersType | undefined>
  >;
}) => {
  const [filter, setFilter] = useState<FilterType>({
    name: '',
    star: [],
    elemental: [],
    weaponType: [],
  });
  const [display, setDisplay] = useState<ImageCardType[]>([]);
  const [filteredCharacters, setFilteredCharacters] = useState<
    CharactersType[]
  >([]);

  const converImageCard = (list: CharactersType[]) => {
    const result: ImageCardType[] = list.map((data) => {
      return {
        id: data.id,
        src: data.avatar_img_path,
        star: data.star,
        weapon_type: data.weapon_type,
        name: data.name,
        elemental: data.elemental,
      };
    });

    setDisplay(result);
  };

  const selectCharacter = (id: number) => {
    const selected = characters.find((data) => data.id === id);

    if (selected) setSelectedCharacter(selected);
  };

  useEffect(() => {
    converImageCard(characters);
  }, [characters]);

  return (
    <Wraper selected={selectedCharacter !== undefined}>
      <FilterBox
        starButtons={[5, 4]}
        showElementals
        showWeaponType
        filter={filter}
        setFilter={setFilter}
      />

      <ImageTable
        setSelectedId={selectCharacter}
        list={display}
        filter={filter}
        displayElemental
      />
    </Wraper>
  );
};

export default CharacterList;
