import { CharacterType, CharactersType } from './interfaces/characInterface';
import { API_PATH } from './constant';
import { ApiAgent } from './agent';
import { ItemFilterType } from './interfaces/itemInterface';

export class ItemAPI {
  private agent = new ApiAgent({ path: API_PATH.ITEM, getMethod: 'POST' });

  constructor() {}

  public getAllItem = async (
    filter: ItemFilterType,
  ): Promise<CharactersType[]> => {
    const { data } = await this.agent.getter({ body: filter });

    return data;
  };
}
