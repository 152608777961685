import { Card } from '@components/frame';
import AddableButton from '@components/inputs/AddableButton';
import ImageCard, { ImageCardType } from '@components/layouts/ImageCard';
import { TodoType } from '@types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import IconButton from '@components/inputs/IconButton';
import { FaWindowClose } from 'react-icons/fa';

const Wraper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0;
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }
  > div + div {
    flex: none;
    height: max-content;
  }
`;

const Content = styled.div<{ $today?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg.SECONDARY};
  padding: 5px;
  height: 100%;
  overflow-y: scroll;

  > div {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props?.$today &&
    css`
      background: ${({ theme }) => theme.bg.TERTIARY};
    `}
`;

const BtnWraper = styled.div`
  width: 100%;
  height: 32px;
  max-height: 32px;
  bottom: 0;
  padding: 5px;
  border-top: 1px solid ${({ theme }) => theme.separator.OPAQUE};
`;

const TodoCardWraper = styled(Card)<{ $today?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 84px;
  max-height: 84px;
  padding: 5px;
  border-radius: ${({ theme }) => theme.radius.CONTENT};
  /* background: ${({ theme }) => theme.bg.PRIMARY}; */

  > div {
    display: flex;
    height: 80%;
  }
  > div + div {
    flex: 1;
    margin-top: auto;
  }

  ${(props) =>
    props?.$today &&
    css`
      border: 2px solid ${({ theme }) => theme.separator.OPAQUE};
    `}
`;

const CloseButton = styled.div`
  width: max-content;
  margin-left: auto;
`;

const TodoCard = ({
  data,
  today,
  memo,
  onDelete,
}: {
  data: ImageCardType;
  today?: boolean;
  memo?: string;
  onDelete?: () => void;
}) => {
  return (
    <TodoCardWraper $today={today}>
      <div>
        <ImageCard
          data={data}
          hiddenName
          style={{ maxWidth: '52px', maxHeight: '52px', boxShadow: 'none' }}
        />
        <CloseButton>
          <FaWindowClose
            style={{ color: '#b72121', cursor: 'pointer' }}
            size={'1.2em'}
            onClick={onDelete}
          />
        </CloseButton>
      </div>
      <div>{memo}</div>
    </TodoCardWraper>
  );
};

const TodoDayColumn = ({
  todayId,
  columnId,
  todoList,
  onClick,
  onDelete,
}: {
  todayId: number;
  columnId: number;
  todoList: TodoType[];
  onClick?: () => void;
  onDelete?: (todo: TodoType) => void;
}) => {
  const [todos, setTodos] = useState<TodoType[]>([]);

  const filterList = (todos: TodoType[]) => {
    const result: TodoType[] = [];

    todos.forEach((data) => {
      if (data.dow) {
        data.dow.forEach((day) => {
          if (day === columnId) result.push(data);
        });
      }
    });

    setTodos(result);
  };

  useEffect(() => {
    filterList(todoList);
  }, [todoList]);

  return (
    <Wraper>
      <Content $today={todayId === columnId}>
        {todos.map((data) => (
          <TodoCard
            key={data.data.name}
            data={data.data}
            today={todayId === columnId}
            memo={data.memo}
            onDelete={() => {
              if (onDelete) onDelete(data);
            }}
          />
        ))}
      </Content>
      <BtnWraper>
        <AddableButton iconSize={1.5} onClick={onClick}></AddableButton>
      </BtnWraper>
    </Wraper>
  );
};

export default TodoDayColumn;
