import React, { useEffect } from 'react';
import styled from 'styled-components';
import WeekCalendar from './WeekCalendar';
import TodoManager from './TodoManager';

const Wraper = styled.div`
  width: 100%;
  height: 100%;

  > div {
    display: flex;
    padding: ${({ theme }) => theme.padding.LARGE};
    width: 100%;
    height: 100%;

    > div {
      width: 75%;
    }
    > div + div {
      margin-left: 20px;
      flex: 1;
    }
  }
`;

const index = () => {
  return (
    <Wraper>
      <div>
        <div>
          <WeekCalendar />
        </div>
        <div>
          <TodoManager />
        </div>
      </div>
    </Wraper>
  );
};

export default index;
