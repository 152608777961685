import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CustomModal from '@components/utils/Modal';
import WeekCalendarItemList from './WeekCalendarItemList';
import { ItemAPI } from '@apis/item';
import { ItemType } from '@apis/interfaces/itemInterface';
import { ImageCardType } from '@components/layouts/ImageCard';
import { Button } from '@components/elements';
import IconInput from '@components/inputs/IconInput';
import { TodoType } from '@types';

const Wraper = styled.div`
  width: 1094px;
  height: 800px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    border-bottom: 1px solid ${({ theme }) => theme.separator.OPAQUE};
    padding-bottom: 10px;

    > p {
      font-size: x-large;
    }
  }

  > div + div {
    margin-top: 30px;
    /* flex: 1; */
    padding: 0;
    border: none;
  }
`;

const BtnListWraper = styled.div`
  display: flex;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    width: max-content;
    font-size: larger;
    padding-right: 10px;
    border-right: 1px solid ${({ theme }) => theme.separator.OPAQUE};
  }
  > div + div {
    border: none;
    margin-left: 10px;
  }
`;

const RoundButton = styled.div<{ $selected?: boolean }>`
  width: 40px;
  height: 40px;
  margin-right: 15px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 5px;
    cursor: pointer;
    background: ${({ theme }) => theme.cement.PRIMARY.DEFAULT};
    border-radius: 1.5rem;

    &:hover {
      background: ${({ theme }) => theme.cement.PRIMARY.SECONDARY};
    }

    ${(props) =>
      props?.$selected &&
      css`
        background: ${({ theme }) => theme.cement.PRIMARY.SECONDARY};
      `}
  }

  &:last-of-type {
    margin: 0;
  }
`;

const ButtonList = ({
  selected,
  onClick,
}: {
  selected: number;
  onClick: (repect: number) => void;
}) => {
  const dow = ['+0', '+1', '+2', '+3', '+4', '+5', '+6', '+7'];

  const click = (data: string) => {
    const num = Number(data.slice(1, 2));

    onClick(num);
  };

  return (
    <BtnListWraper>
      <div>
        <p>반복</p>
      </div>
      <div>
        {dow.map((data) => (
          <RoundButton
            key={data}
            $selected={selected === Number(data.slice(1, 2))}
          >
            <div onClick={() => click(data)}>{data}</div>
          </RoundButton>
        ))}
      </div>
    </BtnListWraper>
  );
};

const index = ({
  dowId,
  open,
  onClose,
  addTodo,
}: {
  dowId: number;
  open?: boolean;
  onClose?: () => void;
  addTodo?: (todo: TodoType) => void;
}) => {
  const [todo, setTodo] = useState<TodoType>({
    type: 'custom',
    data: {
      id: 0,
      src: '',
      star: 0,
      weapon_type: 0,
    },
    loop: {
      startDate: new Date(),
      cycle: 0,
    },
    dow: [0],
  });
  const [itemList, setItemList] = useState<ItemType[]>([]);
  const [displayList, setDisplayList] = useState<ImageCardType[]>([]);
  const items = new ItemAPI();

  const getAllItems = async () => {
    const result = await items.getAllItem({ abyssal: false });

    convertToImageCard(result);

    setItemList(result);
  };

  const convertToImageCard = async (items: ItemType[]) => {
    const result: ImageCardType[] = [];

    for (let i of items) {
      result.push({
        id: i.id,
        src: i.img_path,
        name: i.name,
        star: 0,
        weapon_type: 0,
      });
    }

    setDisplayList(result);
  };

  const onConfirm = () => {
    if (addTodo) addTodo(todo);
    if (onClose) onClose();
  };

  useEffect(() => {
    getAllItems();
  }, []);

  useEffect(() => {
    setTodo((props) => {
      return { ...props, dow: [dowId] };
    });
  }, [dowId]);

  return (
    <CustomModal open={open}>
      <Wraper>
        <div>
          <p>일정 추가</p>
        </div>
        {/* <div>
          <ButtonList
            selected={todo.loop?.cycle || 0}
            onClick={(repect) =>
              setTodo((props) => {
                return {
                  ...props,
                  loop: { startDate: props.loop?.startDate, cycle: repect },
                };
              })
            }
          />
        </div> */}
        <div style={{ marginTop: '30px', flex: 1, overflow: 'hidden' }}>
          <WeekCalendarItemList
            list={displayList}
            setSelected={(selected) => {
              setTodo((props) => {
                return { ...props, data: selected };
              });
            }}
          />
        </div>
        <div>
          <IconInput
            placeHolder="메모"
            iconHidden
            onChange={(e) => {
              setTodo((props) => {
                return { ...props, memo: e.target.value };
              });
            }}
          />
        </div>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 0,
          }}
        >
          <Button onClick={onConfirm}>추가</Button>
          <Button
            $color="error"
            style={{ marginLeft: '20px' }}
            onClick={() => {
              if (onClose) onClose();
            }}
          >
            취소
          </Button>
        </div>
      </Wraper>
    </CustomModal>
  );
};

export default index;
